<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <div class="card shadow rounded-5 hborder pe-0 ps-1 pt-0 pb-0">
      <div class="card-body pt-0">
        <div class="row justify-content-center pt-2">
          <div class="col col-sm-6 text-center">
            <div v-if="hasError" class="alert alert-danger p-2"> {{ message }} </div>
          </div>
        </div>
        <div class="row pt-2 pb-3">
          <div class="col ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <router-link to="/brandview"><img src="/assets/icons/back-arrow.svg" alt="icon"
                      class="icon-class-bg" /></router-link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <!-- Brand Name Display -->
        <div class="text-center mb-5 mt-4">
          <h2 class="brand-name">{{ brandName }}</h2>
        </div>
        <!-- Analysis Stats Row -->
        <div class="row mb-4 text-center">
          <div class="col-12 col-sm-12">
            <div class="stat-card shadow-lg p-4 mb-4">
              <h4>Generated Designs</h4>
              <p class="stat-number">{{ generatedDesigns }}</p>
            </div>
          </div>
        </div>

        <!-- Recent Designs Section -->
        <div>
          <h3 class="mb-4 text-center">Recent Designs</h3>
          <div class="row g-4">
            <div v-for="(design, index) in recentDesigns" :key="index" class="col-12 col-sm-6 col-lg-4">
              <div class="card design-card shadow-sm">
                <div class="card-body text-center">
                  <!-- Lazy Loading Image -->
                  <div class="design-image-container mb-3">
                    <img v-lazy="BASE_URL + design.final_image" src="/assets/images/logo.png" class="design-image"
                      alt="Design Image">
                  </div>
                  <!-- Design Info -->
                  <h5 class="card-title">{{ design.title }}</h5>
                  <p class="card-text">{{ design.design_size }}</p>
                  <p class="card-text"><small class="text-muted">{{ design.create_at }}</small></p>

                  <!-- Design Type Icon -->
                  <div v-if="getIconForDesignType(design.design_size)" class="design-type-icon mb-3">
                    <img :src="BASE_URL + getIconForDesignType(design.design_size)" alt="Design Type Icon"
                      class="design-type-img">
                  </div>

                  <!-- View Design Button -->
                  <router-link :to="'/updesign/' + design.id" class="btn btn-primary">
                    View Design
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import globalErrorHandler from "@/globalErrorHandler";

export default {
  name: 'BrandAnalysis',
  data() {
    return {
      brandName: '',
      creditsUsed: 50, // Static for now
      generatedDesigns: 0,
      recentDesigns: [],
      nextPage: '',
      loadMore: false,
      brandId: this.$route.params.id,
      hasError: false,
      message: '',
    };
  },
  computed: {
    ...mapGetters(["BASE_URL", "POST_SIZES"]),
  },
  methods: {
    // Fetch designs based on the brand
    getRecentDesigns() {
      this.$http.get(`/api/templates/list/?brand=${this.brandId}`).then(res => {
        this.recentDesigns = res.data.results;
        this.nextPage = res.data.next;
        this.generatedDesigns = res.data.count; // Use the count from the API response to set the total number of designs
        if (this.recentDesigns.length > 0) {
          this.brandName = this.recentDesigns[0].brand_name; // Set brand name from the first result
        }
      }).catch(err => {
        this.hasError = true;
        this.message = globalErrorHandler(err.data)[0];
        window.scroll(0, 0);

      });
    },
    // Load more designs on scroll
    loadMoreDesigns() {
      if (this.nextPage && !this.loadMore) {
        this.loadMore = true;
        this.$http.get(this.nextPage).then(res => {
          this.recentDesigns.push(...res.data.results);
          this.generatedDesigns = res.data.count; // Continue using the API count for dynamic updates
          this.nextPage = res.data.next;
          this.loadMore = false;
        }).catch(err => {
          this.hasError = true;
          this.message = globalErrorHandler(err.data)[0];
          window.scroll(0, 0);

          this.loadMore = false;
        });
      }
    },
    // Method to find the correct icon for a design size
    getIconForDesignType(design_Size) {
      const size = this.POST_SIZES.find((x) => design_Size.includes(x.title));
      return size ? size.icon : null;
    },
    handleScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (scrollPosition >= document.body.offsetHeight - 100) {
        this.loadMoreDesigns();
      }
    }

  },
  created() {
    this.getRecentDesigns();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
/* Brand Name Styling */
.brand-name {
  font-size: 2.5rem;
  font-weight: bold;
  color: #f50a74;
}

/* Stat Cards */
.stat-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: bold;
  color: #f50a74;
}

/* Design Card */
.design-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 1rem;
  border-radius: 24px;
  background: #fff;
  min-height: 451px;
}

.design-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

/* Design Image Container */
.design-image-container {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.design-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 14px;
}

/* Design Type Icon */
.design-type-icon img {
  max-width: 30px;
  max-height: 30px;
  object-fit: contain;
}

.text-center {
  text-align: center;
}

.btn-primary {
  background-color: #eb804b;
  border-color: #F06520FF;
}

.btn-primary:hover {
  background-color: #f6047b;
  border-color: #f6047b;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

.icon-class-bg {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}
</style>
