<template>
  <div class="container py-5">
    <div class="row pt-3">
      <div class="col-12 text-center">
        <h3>Welcome Soda Marketing</h3>
      </div>
      <div class="col-12 text-center">
        <h6>Please Create Password With Continue</h6>
      </div>
    </div>
    <div class="row pt-4 justify-content-center">
      <div class="col-md-8 col-lg-6">
        <div class="card br-radius">
          <div v-if="errors.length > 0" class="alert alert-danger text-center p-1">
            <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
          </div>
          <div class="card-body">
            <form @submit.prevent="acceptInvitation">
              <div class="row">
                <div class="col py-2">
                  <label for="">Create Password</label>
                  <div class="face-fram">
                    <div v-if="changeType == 'password'" class="password_Icon" @click.prevent="methodShowType">
                      <i class="fa-regular fa-eye-slash"></i>
                    </div>
                    <div v-else class="password_Icon" @click.prevent="methodShowType">
                      <i class="fa-regular fa-eye"></i>
                    </div>
                    <input :type="changeType" v-model="password" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col py-2">
                  <label for="">Repeat Password</label>
                  <div class="face-fram">
                    <div v-if="changeFiled == 'password'" class="password_Icon" @click.prevent="ShowType">
                      <i class="fa-regular fa-eye-slash"></i>
                    </div>
                    <div v-else class="password_Icon" @click.prevent="ShowType">
                      <i class="fa-regular fa-eye"></i>
                    </div>
                    <input :type="changeFiled" v-model="repeatPassword" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row py-3">
                <div v-if="isLoading" class="col text-center">
                  <loader/>
                </div>
                <div v-else class="col text-center">
                  <button type="submit" class="btn btn-generate">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {AcceptInvitations} from '@/apis';
import globalErrorHandler from "@/globalErrorHandler";

export default {
  name: "invitation",
  data() {
    return {
      email: this.$route.query.email,
      code: this.$route.query.code,
      invitationError: false,
      isLoading: false,
      errors: [],
      password: "",
      repeatPassword: "",
      changeType: "password",
      changeFiled: "password",
    }
  },
  methods: {
    async acceptInvitation() {
      this.isLoading = true;
      try {
        await AcceptInvitations({
          email: this.email,
          code: this.code,
          password: this.password,
          re_password: this.repeatPassword
        });
        this.isLoading = false;
        window.location.href = "/login";
      } catch (err) {
        this.errors = globalErrorHandler(err);
        this.isLoading = false;
        window.scroll(0, 0);
      }
    },
    methodShowType() {
      this.changeType = this.changeType == "password" ? "text" : "password";
    },
    ShowType() {
      this.changeFiled = this.changeFiled == "password" ? "text" : "password";
    },
  }
}
</script>