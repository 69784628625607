import { createStore } from 'vuex';
import axios from 'axios';
import { retrieveBaseSubscriptions, fetchPlans, fetchAddons, retrieveUserData } from '@/apis';
import globalErrorHandler from '@/globalErrorHandler';
import gtmPlugin from '@/plugins/gtmPlugin';


export default createStore({
  state: {
    isLogin: false,
    userName: "",
    firstName: "",
    lastName: "",
    mobile: null,
    email: "",
    isLoading: true,
    myRoute: "",
    baseURL: "https://backend.sodamarketing.ai",
    postSizes: [],
    globalLoading: false,
    baseSubscription: null, // base subscription
    isFetchingSubscription: false, // flag to prevent overlapping API calls

  },
  getters: {
    GET_USER(state) {
      return state.isLogin;
    },
    GET_DATA_USER(state) {
      return {
        firstName: state.firstName,
        lastName: state.lastName,
        mobile: state.mobile,
        email: state.email,
        userName: state.userName,
      }
    },
    DATA_LOADING(state) {
      return state.isLoading;


    },
    BASE_URL(state) {
      return state.baseURL;

    },
    POST_SIZES(state) {
      return state.postSizes;

    },
    GET_ROUTE(state) {
      return state.myRoute;
    },
    GET_BASE_SUBSCRIPTION(state) {
      return state.baseSubscription;
    },
  },
  mutations: {
    SET_USER_DATA(state, { user, token, post_sizes }) {
      state.isLogin = true;
      state.isLoading = false;
      localStorage.setItem('token', token);
      state.postSizes = post_sizes;
      state.firstName = user.first_name;
      state.lastName = user.last_name;
      state.mobile = user.mobile;
      state.email = user.email;
      state.userName = user.username;
      localStorage.setItem('role', user.access_role);
    },
    REMOVE_USER_DATA(state) {
      state.isLogin = false;
      state.isLoading = false;
      state.baseSubscription = null;
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      delete axios.defaults.headers.common['Authorization'];
    },
    SET_ROUTE: (state, route) => state.myRoute = route.name,
    SET_GLOBAL_LOADING: (state, isLoading) => state.globalLoading = isLoading,
    SET_BASE_SUBSCRIPTION: (state, status) => state.baseSubscription = status,
  },
  actions: {
    async checkUser(context) {
      if (localStorage.getItem("token")) {
        const user_token = localStorage.getItem("token");
        axios.defaults.headers.common['Authorization'] = `Token ${user_token}`;
        try {
          const userRes = await retrieveUserData(); // retrieve user data
          context.commit("SET_USER_DATA", { user: userRes.data, token: user_token, post_sizes: userRes.post_sizes });

          localStorage.setItem('role', userRes.data.access_role);
          try {
            const subRes = await retrieveBaseSubscriptions();
            context.commit("SET_BASE_SUBSCRIPTION", subRes);
          } catch (subErr) {
            const formattedErrors = globalErrorHandler(subErr.response?.data?.errors || subErr.message);
            console.error("Subscription Update Error:", formattedErrors);
            context.commit("SET_BASE_SUBSCRIPTION", null);
          }
        } catch (error) {
          console.error("Check user error:",
            globalErrorHandler(error.response?.data?.errors || error.message));
          context.commit("REMOVE_USER_DATA");
        }
      } else {
        context.commit("REMOVE_USER_DATA");
      }
    },
    async updateBaseSubscription(context) {

      if (context.state.isFetchingSubscription) {
        return;
      }

      // Set a flag indicating that a fetch is in progress
      context.state.isFetchingSubscription = true;

      try {
        const response = await retrieveBaseSubscriptions();
        context.commit("SET_BASE_SUBSCRIPTION", response);
      } catch (error) {
        const formattedErrors = globalErrorHandler(error.response?.data?.errors || error.message);
        console.error("Subscription Update Error:", formattedErrors);
        context.commit("SET_BASE_SUBSCRIPTION", null);
      } finally {
        context.state.isFetchingSubscription = false;

      }
    },

    // Fetch available plans and handle errors
    async fetchAvailablePlans(context) {
      try {
        const response = await fetchPlans();
        context.commit("SET_PLANS", response.data.plans); // Assuming there's a mutation named SET_PLANS
      } catch (error) {
        const formattedErrors = globalErrorHandler(error.response?.data?.errors || error.message);
        console.error("Fetch Plans Error:", formattedErrors);
      }
    },

    // Fetch additional addons for subscription
    async fetchAddons(context) {
      try {
        const response = await fetchAddons();
        context.commit("SET_ADDONS", response.data.addons); // Assuming there's a mutation named SET_ADDONS
      } catch (error) {
        const formattedErrors = globalErrorHandler(error.response?.data?.errors || error.message);
        console.error("Fetch Addons Error:", formattedErrors);
      }
    },

  },
  modules: {},
  plugins: [gtmPlugin],
});
