<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <div class="card shadow rounded-5 hborder pe-0 ps-1 pt-0 pb-0">
      <div class="card-body pt-0 mt-4">

        <!--begin:breadcrumb-->

        <div class="title-container ">
          <!-- Back Arrow Link -->
          <div class="breadcrumb-mobile">
            <router-link to="/" class="back-arrow me-2">
              <img src="/assets/icons/back-arrow.svg" alt="Back" class="icon-class-bg" />
            </router-link>
          </div>
          <!-- Page Title -->
          <h2 class="page-title mb-0">Subscription & Billing Information</h2>
        </div>

        <!--end:breadcrumb-->

        <!-- Error Messages -->
        <div v-if="errors" class="alert alert-danger mx-4 text-center p-1 br-radius">
          <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
        </div>
        <!-- First Card: Available Credits -->
        <div class="container-fluid billing-page d-flex justify-content-center">
          <div class="col-lg-12 col-md-8 col-12">
            <div class="card credits-card mb-4">
              <div
                class="card-body d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-center">
                <!-- Left Column: Logo and Total Credits Information -->
                <div class="d-flex flex-column flex-sm-row align-items-center mb-3 mb-lg-0">
                  <div class="logo-column me-sm-4 mb-2 mb-sm-0">
                    <img src="/assets/images/Remaining-Credits.png" class="remaining-image" alt="Company Logo" />
                  </div>
                  <!-- Available Total Credits -->
                  <div class="text-center">
                    <div class="text-center">
                      <h5 class="text-black font-w600 mb-0">Remaining Credits </h5>
                    </div>
                    <div class="billing-color font-70">
                      {{ remainingTotalCredits }}
                    </div>
                    <div>
                      <button class="btn billing-button" @click="handleBuyCreditsClick">
                        Buy Credits
                      </button>
                    </div>
                  </div>
                </div>
                <!--begin:modal-BuyCredits-->
                <div class="modal fade" id="addCreditsModal" tabindex="-1" aria-labelledby="Credits-ModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="row pt-3">
                        <div class="col-10 col-sm-10">
                          <h5 class="modal-title text-color" id="Credits-ModalLabel">
                            <div class="line-G" style="width: 6%;"></div>
                            Choose Your Credits Bundle
                          </h5>
                        </div>
                        <div class="col-2 col-sm-2 text-center">
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                      </div>
                      <div v-if="errors" class="alert alert-danger mx-4 text-center p-1 br-radius">
                        <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
                      </div>
                      <div class="modal-body">
                        <div class="pb-2">Select</div>
                        <div class="row">
                          <div class="col-12 col-sm-12 py-1" v-for="(credit, index) in creditAddon?.pricing"
                            :key="index">
                            <div class="form-control d-flex billing-border">
                              <label class="mouse">
                                <input class="form-check-input payment-color billing-border mx-2" :value="credit?.id"
                                  v-model="balance" type="radio" name="flexRadioDefault">
                                Credits {{ credit?.features?.credits }}
                              </label>
                              <div class="payment-color ms-auto">USD {{ credit?.amount / 100 }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                        <div class="text-center" v-if="isLoading">
                          <loader />
                        </div>
                        <button v-else type="button" class="btn billing-button w-75 m-auto"
                          @click.prevent="attachCredits">Pay
                        </button>
                        <div class="m-auto pt-2">Your Saved Payment Method will be charged Immediately</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Success Modal -->
                <div v-if="showSuccessPopup" class="modal fade show d-block" tabindex="-1" aria-hidden="true"
                  style="background: rgba(0, 0, 0, 0.5);">
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content text-center p-4">
                      <img src="/assets/images/checked.png" alt="Success" class="mb-3 align-self-center"
                        style="width: 50px; height: 50px;" />
                      <h5 class="text-success">Success!</h5>
                      <p>Your credits have been successfully added.</p>
                    </div>
                  </div>
                </div>
                <!--end:modal-BuyCredits-->


                <div class="text-center text-lg-start">
                  <!-- Subscription Credits Section -->
                  <div class="d-flex align-items-center credit-detail">
                    <!-- Image for Subscription Credits -->
                    <div class="logo-column me-3 align-self-center align-self-lg-start">
                      <img src="/assets/images/Subscription-Credits.png" class="icon-image"
                        alt="Subscription Credits Icon" />
                    </div>
                    <!-- Text Content -->
                    <div>
                      <h6>Credits From Subscription</h6>
                      <p>
                        {{ GET_BASE_SUBSCRIPTION?.credits.remaining -
                          GET_BASE_SUBSCRIPTION?.credits_addons_only?.remaining }} /
                        {{ GET_BASE_SUBSCRIPTION?.credits.total - GET_BASE_SUBSCRIPTION?.credits_addons_only?.total }}
                      </p>
                    </div>
                  </div>



                  <!-- Add-on Credits Section -->
                  <div class="d-flex align-items-center credit-detail mt-3">
                    <!-- Image for Add-on Credits -->
                    <div class="logo-column me-3 align-self-center align-self-lg-start">
                      <img src="/assets/images/Addon-Credits.png" class="icon-image" alt="Add-on Credits Icon" />
                    </div>
                    <!-- Text Content -->
                    <div>
                      <h6>Add-on Credits</h6>
                      <p>
                        {{ GET_BASE_SUBSCRIPTION?.credits_addons_only.remaining || 0 }} /
                        {{ GET_BASE_SUBSCRIPTION?.credits_addons_only.total || 0 }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Second Card: Subscription Plan and Addons Information -->
        <div class="card subscription-card mb-4">
          <div class="card-body">
            <div class="row">
              <!-- Left Column: Subscription Plan Details -->
              <div class="col-12 col-xxl-4 text-center text-xxl-start mb-4 mb-xxl-0">
                <h4 class="font-w600 mb-4">Your Main Subscription Plan:</h4>
                <h6 class="font-w600">Name: {{ GET_BASE_SUBSCRIPTION?.display_name }} Plan</h6>
                <p>Valid till: {{ GET_BASE_SUBSCRIPTION?.current_period_end }}</p>
                <!-- Status Display -->
                <p>Status:
                  <span
                    :class="['badge', GET_BASE_SUBSCRIPTION?.status === 'active' ? 'badge-success' : 'badge-warning']">
                    {{ GET_BASE_SUBSCRIPTION?.status }}
                  </span>
                </p>
                <p>
                  <button class="btn btn-primary upgrade-btn mb-3 me-4" @click="openUpgradeModal">
                    Change Plan
                  </button>
                  <button class="btn btn-danger upgrade-btn mb-3" @click="openCancellationModal"
                    :disabled="GET_BASE_SUBSCRIPTION?.status === 'cancelling'">
                    Cancel Plan
                  </button>

                </p>

                <PlansModal v-if="showPlansModal" :rows="rows" :billingCycle.sync="billingCycle"
                  @close="showPlansModal = false" @selectPlan="selectPlan" />
              </div>
              <!-- Right Column: Addons Usage Section -->
              <div class="col-12 col-xxl-8">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-4">
                    <AddonCard title="User Seats" logo="/assets/images/User-Seats.png" :used="remainingUsers"
                      :total="totalUsers" @add="openAddModal('User Seats')" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-4">
                    <AddonCard title="Brands" logo="/assets/images/Storage.png" :used="remainingBrands"
                      :total="totalBrands" @add="openAddModal('Brands')" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-4">
                    <AddonCard title="Storage(GB)" logo="/assets/images/Brands.png" :used="remainingStorage"
                      :total="totalStorage" @add="openAddModal('Storage')" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Row with Saved Cards and Subscriptions -->
      <div class="row">
        <!-- Saved Cards Section -->
        <section class="col-12 col-xl-6 mb-4 mb-xl-0">
          <div class="card">
            <div class="card-header">
              <h4 class="text-center text-xl-start">Saved Cards</h4>
            </div>
            <div class="card-body">
              <div class="row g-3 justify-content-center justify-content-xl-start">
                <SavedCard :items="savedCards" type="card" @refreshCards="fetchCards" />
              </div>
              <div class="text-center text-xl-end mt-3">
                <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addCardModal">Add New
                  Card</button>
              </div>
            </div>
          </div>
        </section>

        <!-- Subscriptions Section -->
        <section class="col-12 col-xl-6">
          <div class="card">
            <div class="card-header">
              <h4 class="text-center text-xl-start">Add-on Subscription Plans</h4>
            </div>
            <div class="card-body">
              <!-- Placeholder when subscriptions are empty -->
              <div v-if="subscriptions.length === 0" class="text-center">
                <div class="placeholder-card border rounded p-3">
                  <p>No Add-on Subscription Plans found.</p>
                </div>
              </div>
              <div v-else class="subscription-summary-card row justify-content-center">
                <SubscriptionSummaryCard v-for="(count, type) in subscriptionCounts" :key="type" :type="type"
                  :count="count" @viewDetails="goToSubscriptionDetails" />
              </div>
            </div>
          </div>
        </section>
      </div>

      <!-- Add Card Modal -->
      <div class="modal fade" id="addCardModal" tabindex="-1" aria-labelledby="addCardModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addCardModalLabel">Add New Card</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <!-- Render PaymentForm component inside the modal -->
              <PaymentForm :then-promising="handleCardAdded" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Billing History Section -->
    <section class="card shadow rounded-5 hborder mt-4 mb-4">
      <div class="card-body pt-0 mt-4">
        <!-- Placeholder when subscriptions are empty -->

        <div class="card-body">
          <BillingHistory :history="currentPageData" />
          <!-- Pagination Controls -->
          <div class="card-body mt-4 pagination-container">
            <button :disabled="currentPage === 1" @click="goToPage(1)" class="page-button">
              First
            </button>
            <button :disabled="currentPage === 1" @click="goToPreviousPage" class="page-button">
              Previous
            </button>

            <!-- Ellipsis at the beginning -->
            <span v-if="visiblePageNumbers[0] > 2">
              <button @click="goToPage(1)" class="page-button">1</button>
              <span class="page-ellipsis">...</span>
            </span>
            <span v-else-if="visiblePageNumbers[0] === 2">
              <button @click="goToPage(1)" class="page-button">1</button>
            </span>

            <!-- Visible Page Numbers -->
            <button v-for="page in visiblePageNumbers" :key="page"
              :class="['page-button', { active: currentPage === page }]" @click="goToPage(page)">
              {{ page }}
            </button>

            <!-- Ellipsis at the end -->
            <span v-if="visiblePageNumbers[visiblePageNumbers.length - 1] < totalPages - 1">
              <span class="page-ellipsis">...</span>
              <button @click="goToPage(totalPages)" class="page-button">
                {{ totalPages }}
              </button>
            </span>
            <span v-else-if="visiblePageNumbers[visiblePageNumbers.length - 1] === totalPages - 1">
              <button @click="goToPage(totalPages)" class="page-button">
                {{ totalPages }}
              </button>
            </span>

            <button :disabled="currentPage === totalPages" @click="goToNextPage" class="page-button">
              Next
            </button>
            <button :disabled="currentPage === totalPages" @click="goToPage(totalPages)" class="page-button">
              Last
            </button>
          </div>


        </div>
      </div>
    </section>


    <!-- Add-On Modal -->
    <Modal v-if="showAddModal" :type="selectedAddOnType" @close="showAddModal = false" />

    <!-- Upgrade Modal for Free Users -->
    <div v-if="showUpgradeModal" class="modal fade show d-block" tabindex="-1" aria-hidden="true"
      style="background: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center p-4 position-relative">
          <button type="button" class="btn-close position-absolute top-0 end-0 m-3" aria-label="Close"
            @click="closeUpgradeModal"></button>
          <h5 class="text-primary">{{ modalMessage }}</h5>
          <p>Upgrade your plan to access this feature.</p>
          <button class="btn btn-primary mt-3" @click="openPlanModal">Pricing</button>
        </div>
      </div>
    </div>

    <!-- Cancellation Modal -->
    <div class="modal fade" tabindex="-1" :class="{ show: showCancellationModal }" style="display: block;"
      v-if="showCancellationModal" @click.self="!isCancelling && closeCancellationModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Confirm Plan Cancellation</h5>

            <button type="button" class="btn-close" aria-label="Close" @click="closeCancellationModal"
              :disabled="isCancelling"></button>
          </div>
          <div class="modal-body">
            <h6>Are you sure you want to cancel?</h6>
            <p>
              Please note that this action is irreversible. Once you proceed, all
              your data and generated designs will be permanently deleted.
            </p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger" @click="confirmCancellation" :disabled="isCancelling">
              <span v-if="isCancelling" class="spinner-border spinner-border-sm" role="status"
                aria-hidden="true"></span>
              Yes, cancel Plan
            </button>

            <button class="btn btn-success" @click="closeCancellationModal" :disabled="isCancelling">
              No, do not cancel my plan
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Backdrop -->
    <div class="modal-backdrop fade show" v-if="showCancellationModal"></div>

    <!-- Success Modal -->
    <div v-if="showSuccessCancelPopup" class="modal fade show d-block" tabindex="-1" aria-hidden="true"
      style="background: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center p-4">
          <img src="/assets/images/checked.png" alt="Success" class="mb-3 align-self-center"
            style="width: 50px; height: 50px;" />
          <h5 class="text-success">Success!</h5>
          <p>Your plan will be cancelled at: {{ GET_BASE_SUBSCRIPTION?.current_period_end }} </p>
          <button class="btn btn-success mt-5" @click="closeSuccessCancelPopupButton">
            Okay
          </button>
        </div>
      </div>
    </div>

    <!-- Error Message -->
    <div v-if="cancellationError" class="alert alert-danger mt-3">
      Failed to cancel your subscription. Please try again.
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddonCard from '@/views/Billing/New/AddonCard.vue';
import SavedCard from '@/views/Billing/New/SavedCard.vue';
import SubscriptionCard from '@/views/Billing/New/SubscriptionCard.vue';
import BillingHistory from '@/views/Billing/New/BillingHistory.vue';
import Modal from '@/views/Billing/New/Modal.vue';
import globalErrorHandler from "@/globalErrorHandler";
import PaymentForm from "@/views/Billing/PaymentForm.vue";
import SubscriptionSummaryCard from '@/views/Billing/New/SubscriptionSummaryCard.vue';

import { listInvoices, fetchPlans, listPaymentMethods, listSubscriptions, attachAddon, retrieveProductByName, cancelSubscription } from "@/apis";



export default {
  name: 'BillingPage',
  components: {
    AddonCard,
    SavedCard,
    SubscriptionCard,
    BillingHistory,
    Modal,
    PaymentForm,
    SubscriptionSummaryCard
  },
  data() {
    return {
      errors: null,
      creditAddon: null,
      balance: null,
      can_attach_credits: this.GET_BASE_SUBSCRIPTION?.plan === 'paid',
      showAddModal: false,
      selectedAddOnType: '',
      showSuccessPopup: false,
      showSuccessCancelPopup: false,
      billingHistoryData: [],
      isLoading: false,
      savedCards: [],
      subscriptions: [],
      subscriptionCounts: {},
      showUpgradeModal: false,
      showAddModal: false,
      modalMessage: '',
      currentPageData: [],
      totalRecords: 0,
      currentPage: 1,
      itemsPerPage: 5,
      nextUrl: null,
      previousUrl: null,
      maxButtons: 5,
      showCancellationModal: false,
      isCancelling: false,
      cancellationSuccess: false,
      cancellationError: false,
    };
  },
  computed: {
    ...mapGetters(['GET_BASE_SUBSCRIPTION']),

    remainingTotalCredits() {

      return this.GET_BASE_SUBSCRIPTION?.credits?.remaining || 0;
    },
    totalAddonsCredits() {
      return this.GET_BASE_SUBSCRIPTION?.credits_addons_only?.total || 0;
    },

    totalCredits() {
      return this.GET_BASE_SUBSCRIPTION?.credits?.total || 0;
    },
    canAttachCredits() {
      return this.GET_BASE_SUBSCRIPTION?.plan === 'paid';
    },
    currentPlanName() {
      return this.GET_BASE_SUBSCRIPTION?.plan_name || 'Free Plan';
    },
    currentPlanEndDate() {
      return this.GET_BASE_SUBSCRIPTION?.current_period_end || 'N/A';
    },
    totalUsers() {
      return this.GET_BASE_SUBSCRIPTION?.users?.total || 0;
    },
    remainingUsers() {
      return this.GET_BASE_SUBSCRIPTION?.users?.remaining || 0;
    },
    totalBrands() {
      return this.GET_BASE_SUBSCRIPTION?.brands?.total || 0;
    },
    remainingBrands() {
      return this.GET_BASE_SUBSCRIPTION?.brands?.remaining || 0;
    },
    totalStorage() {
      return this.GET_BASE_SUBSCRIPTION?.storage?.total || 0;
    },
    remainingStorage() {
      return this.GET_BASE_SUBSCRIPTION?.storage?.remaining || 0;
    },
    isPaidPlan() {
      return this.GET_BASE_SUBSCRIPTION?.plan === 'paid';
    },
    isFreePlan() {
      return this.GET_BASE_SUBSCRIPTION?.plan === 'free';
    },
    groupedSubscriptions() {
      const grouped = {};
      this.subscriptions.forEach(subscription => {
        const type = subscription.type;
        if (!grouped[type]) {
          grouped[type] = { type, count: 0 };
        }
        grouped[type].count++;
      });
      return Object.values(grouped);
    },
    totalPages() {
      return Math.max(1, Math.ceil(this.totalRecords / this.itemsPerPage));
    },
    visiblePageNumbers() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      const maxButtons = this.maxButtons;

      let startPage, endPage;

      if (totalPages <= maxButtons) {
        // Less than maxButtons total pages so show all
        startPage = 1;
        endPage = totalPages;
      } else {
        const half = Math.floor(maxButtons / 2);
        if (currentPage <= half + 1) {
          startPage = 1;
          endPage = maxButtons;
        } else if (currentPage + half >= totalPages) {
          startPage = totalPages - maxButtons + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - half;
          endPage = currentPage + half;
        }
      }

      const pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    },
    subscriptionId() {
      return this.GET_BASE_SUBSCRIPTION.id;
    },
  },
  mounted() {
    this.retrieveCredits();
    this.fetchCards();
    this.updateMaxButtons();
    window.addEventListener('resize', this.updateMaxButtons);
    this.fetchSubscriptions();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMaxButtons);
  },
  methods: {
    openCancellationModal() {
      this.showCancellationModal = true;
    },
    closeCancellationModal() {
      this.showCancellationModal = false;
    },
    async confirmCancellation() {
      this.isLoading = true;
      this.isCancelling = true;
      this.cancellationSuccess = false;
      this.cancellationError = false;

      try {
        await cancelSubscription(this.subscriptionId, { cancellation_type: 'at_period_end' });
        // Track GTM event for Subscription Cancellation
        this.$gtm.trackEvent({
          event: "Purchase",
          category: "Subscription",
          action: "Cancel Subscription",
          label: this.currentPlanName,
          subscription_id: this.subscriptionId,
          cancellation_type: "at_period_end",
        });

        this.isLoading = false;
        this.isCancelling = false;
        this.showCancellationModal = false;
        this.cancellationSuccess = true;

        // Show Success Modal
        this.showSuccessCancelPopup = true;

        // After 2 seconds, hide the success modal and navigate to /billing

      } catch (error) {
        this.isLoading = false;
        this.isCancelling = false;
        this.cancellationError = true;
        this.showCancellationModal = false;
        this.errors = globalErrorHandler(error);
        window.scroll(0, 0);
      }
    },
    closeSuccessCancelPopupButton() {
      this.showSuccessCancelPopup = false;
      window.location.href = '/billing';
    },

    closeUpgradeModal() {
      this.showUpgradeModal = false;
    },
    updateMaxButtons() {
      const width = window.innerWidth;
      if (width < 576) {
        this.maxButtons = 3;
      } else if (width < 768) {
        this.maxButtons = 5;
      } else if (width < 992) {
        this.maxButtons = 7;
      } else {
        this.maxButtons = 9;
      }
    },

    handleBuyCreditsClick() {
      if (this.isPaidPlan) {
        const addCreditsModal = new bootstrap.Modal(document.getElementById("addCreditsModal"));
        addCreditsModal.show();
      } else {
        this.modalMessage = "Upgrade to be able to Buy Credits";
        this.showUpgradeModal = true;
      }
    },
    openPlanModal() {
      this.showUpgradeModal = false;
      this.$root.openPlansModal();
      // Track GTM event for Upgrade Modal Open
      this.$gtm.trackEvent({
        event: "Purchase",
        category: "Subscription",
        action: "Upgrade Modal Opened",
        label: this.currentPlanName,
        plan: this.currentPlanName,
        remaining_credits: this.remainingTotalCredits,
        subscription_id: this.subscriptionId,
      });

    },

    async fetchCards() {
      try {
        const response = await listPaymentMethods();
        // Map the results to suit the SavedCard component structure
        this.savedCards = response.results.map(card => ({
          id: card.id,
          name: card.type_object.display_brand.charAt(0).toUpperCase() + card.type_object.display_brand.slice(1),
          last4: card.type_object.last4,
          type: card.type_object.brand,
          is_primary: card.is_default,
          expiry: `${card.type_object.exp_month.toString().padStart(2, '0')}/${card.type_object.exp_year}`, // Format MM/YYYY
          logo: `/assets/images/${card.type_object.brand.toLowerCase()}.jpg` // Assuming logos are named as "visa-logo.png" etc.
        }));

      } catch (error) {
        this.errors = globalErrorHandler(error.data);
        window.scroll(0, 0);
      }
    },
    async fetchSubscriptions() {
      try {
        const response = await listSubscriptions();
        this.subscriptions = response.results;

        // Safely access sub.product and sub.product.name to prevent errors
        this.subscriptionCounts = this.subscriptions.reduce((counts, sub) => {
          const type = sub.product?.name || 'Unknown'; // Fallback to 'Unknown' if name is missing
          counts[type] = (counts[type] || 0) + 1;
          return counts;
        }, {});
      } catch (error) {
        this.errors = globalErrorHandler(error.data);
        window.scroll(0, 0);
      }
    },
    goToSubscriptionDetails(type) {
      this.$router.push({ name: 'SubscriptionDetails', params: { type } });
    },

    handleCardAdded() {
      // Refresh the card list after adding a new card
      this.fetchCards();
      // Close the modal
      const modalElement = document.getElementById("addCardModal");
      const modal = bootstrap.Modal.getInstance(modalElement);
      modal.hide();
    },

    async retrieveCredits() {
      this.isLoading = true;
      try {
        this.isLoading = false;
        this.creditAddon = await retrieveProductByName("Credits")
      } catch (err) {
        this.isLoading = false;
        this.errors = globalErrorHandler(error.data);
        window.scroll(0, 0);
      }
    },


    async fetchBillingHistory(page = 1) {
      this.isLoading = true;
      try {
        const lastIndexNeeded = page * this.itemsPerPage;

        while (
          this.billingHistoryData.length < lastIndexNeeded &&
          (this.nextUrl !== null || this.billingHistoryData.length === 0)
        ) {
          let response;
          if (this.billingHistoryData.length === 0) {
            // First time fetching data
            response = await listInvoices(1);
          } else if (this.nextUrl) {
            // Fetch next page from API using nextUrl
            response = await listInvoices(this.nextUrl);
          } else {
            // No more data to fetch
            break;
          }

          // **Add this check to prevent infinite loop**
          if (!response.results || response.results.length === 0) {
            // No results in response, break the loop
            break;
          }

          this.billingHistoryData = this.billingHistoryData.concat(response.results);
          this.nextUrl = response.next;
          this.previousUrl = response.previous;
          if (this.totalRecords === 0) {
            this.totalRecords = response.count;
          }
        }
        this.currentPage = page;
        this.updateCurrentPageData();
      } catch (err) {
        this.errors = globalErrorHandler(err.data);
        window.scroll(0, 0);
      } finally {
        this.isLoading = false;
      }
    },



    updateCurrentPageData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = this.currentPage * this.itemsPerPage;
      this.currentPageData = this.billingHistoryData.slice(startIndex, endIndex);
    },

    goToPage(page) {
      this.fetchBillingHistory(page);
    },

    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.fetchBillingHistory(this.currentPage + 1);
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.fetchBillingHistory(this.currentPage - 1);
      }
    },

    async attachCredits() {
      this.isLoading = true;
      try {
        this.isLoading = true;
        await attachAddon({
          pricing: this.balance
        });
        // Track GTM event for Addon Purchase
        this.$gtm.trackEvent({
          event: "Purchase",
          category: "Addons",
          action: "Addon Purchased",
          label: "Credits",
          value: parseFloat(this.balance / 100), // Assuming balance is in cents
          currency: "USD",
          subscription_id: this.subscriptionId,
        });

        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
          const AddCreditsModal = new bootstrap.Modal(document.getElementById('addCreditsModal'));
          AddCreditsModal.hide();
          this.balance = null;
          this.errors = null;
          this.isLoading = false;
          window.location.reload();


        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.errors = globalErrorHandler(err.data);
        window.scroll(0, 0);
      }
    },
    openAddModal(type) {
      if (this.isPaidPlan) {
        this.selectedAddOnType = type;
        this.showAddModal = true;
      } else {
        this.modalMessage = `Upgrade to add more ${type.toLowerCase()}!`;
        this.showUpgradeModal = true;
      }
    },
    openUpgradeModal() {
      this.$root.openPlansModal();
    },

  },
  created() {
    this.fetchBillingHistory();
    this.fetchSubscriptions();


  }
};
</script>

<style scoped>
.subscription-summary-card {
  background-color: #f8f9fa;
  text-align: center;
}

.circle-counter {
  background-color: #e26600;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center the items as a group */
  margin-bottom: 20px;
  position: relative;
  /* If needed for other absolutely positioned elements */

}

.back-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

}

.icon-class-bg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

/* Adjust icon size on smaller screens */
@media (max-width: 576px) {
  .title-container {
    flex-wrap: nowrap;

  }

  .icon-class-bg {
    width: 24px;
    height: 24px;

  }

  .back-arrow {
    margin-top: -42px;
  }

  .page-title {
    font-size: 1.5rem;
    text-align: center;
  }
}

.page-title {
  text-align: center;
  font-size: 1.8rem;
  /* Adjust size as needed */
  font-weight: 600;
  color: #333;
  margin: 0;

}


.billing-page {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

.credits-card .card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .credits-card .card-body {
    flex-direction: column;
    align-items: flex-start;
  }
}

.card-logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.credit-detail h6 {
  font-weight: 600;
  margin-bottom: 0;
}

.subscription-card {
  background-color: #f8f9fa;
}

.subscription-card .card-body {
  padding: 2rem;
}

.upgrade-btn {
  background-color: #4CAF50;
  color: white;
}

/* Adjust Upgrade Plan button for screens 1390px and smaller */
@media (max-width: 1390px) {
  .upgrade-btn {
    padding: 0.5rem 1rem;
    /* Smaller padding */
    font-size: 0.875rem;
    /* Reduce font size */
    width: auto;
    /* Let the button width adjust automatically */
  }
}

/* Stack columns vertically starting at 1390px and smaller */
@media (max-width: 1390px) {
  .subscription-card .row {
    flex-direction: column;
    /* Stack the columns vertically */
    align-items: center;
  }

  .subscription-card .col-md-4,
  .subscription-card .col-auto {
    width: 100%;
    /* Full width for each card */
    margin-bottom: 1rem;
    /* Space between stacked cards */
  }

  /* Adjust the Upgrade Plan button size for this breakpoint */
  .upgrade-btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    width: auto;
  }
}

/* Further adjustments for even smaller screens */
@media (max-width: 992px) {
  .upgrade-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }
}

/* Further adjustments for even smaller screens */
@media (max-width: 992px) {
  .upgrade-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.85rem;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .subscription-card .row>div {
    margin-bottom: 1.5rem;
  }

  .upgrade-btn {
    width: 100%;
  }
}

.btn-outline-primary {
  border-color: #f31986;
  color: #F9419E;
}

.header-section {
  margin-bottom: 1.5rem;
}

.billing-container {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 1rem 2rem;
}

.icon-class-bg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.credits-section {
  margin-bottom: 1.5rem;
}

.plan-details-card {
  margin-bottom: 1.5rem;
}

.saved-cards,
.billing-history {
  padding-top: 1rem;
  margin-top: 2rem;
  border-top: 1px solid #ddd;
}


.billing-button {
  color: white;
  background-color: #E26600;
  border: none;
}

.billing-button[disabled] {
  background-color: #f5f5f5;
  color: #ccc;
  cursor: not-allowed;
}

.addon-usage {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.container {
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.subscriptions,
.saved-cards {
  margin-top: 20px;
}

@media (max-width: 768px) {

  .subscriptions,
  .saved-cards {
    margin-top: 20px;
  }
}


.container-fluid {
  background-color: #ffffff;
}

.btn-primary {
  background-color: #E26600;
  border-color: #E26600;
}

.btn {
  background-color: #E26600;
  border-color: #E26600;
}

.card-header {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  border-radius: 17px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

}

.badge-success {
  background-color: #28a745;
  color: white;
}

.badge-warning {
  background-color: #E26600;
  color: white;
}

@media screen and (max-width: 1280px) and (min-height: 800px) {
  .billing-page .col-lg-6 {
    /* Adjust the column width or margins for this screen size */
    max-width: 100%;
    padding: 0 20px;
  }

  .billing-page .card.credits-card {
    /* Adjust the card width or margins */
    width: 100%;
    margin: 0 auto;
  }

  .billing-page .card-body {
    flex-direction: column;
    text-align: center;
  }

  .billing-page .modal-dialog {
    /* Resize modal for 1280x800 resolution */
    max-width: 80%;
  }
}

@media screen and (max-width: 1280px) and (min-height: 800px) {
  .col-md-7 .row.g-2 {
    /* Center the AddonCards and adjust spacing */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .col-md-7 .col-12.col-md-4 {
    /* Adjust width for better alignment */
    width: 100%;
    max-width: 300px;
    /* Adjust as needed for optimal display */
    margin: 0 auto;
  }

  .addon-card {
    /* Ensure the addon card fills the container width */
    width: 100%;
    margin: 10px 0;
    padding: 1.2rem;
    /* Slightly increase padding for better readability */
  }

  .addon-card .content-column h6 {
    /* Adjust heading size for readability */
    font-size: 1.1rem;
  }

  .addon-card .content-column p {
    /* Adjust paragraph size */
    font-size: 0.9rem;
  }

  .addon-card .btn-primary {
    width: 100%;
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}

.text-primary {
  color: #F9419E !important;
}

.pagination-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.page-button,
.page-ellipsis {
  margin: 0 0.25rem;
  padding: 0.5rem 0.75rem;
  border: none;
  background-color: #f70081;
  color: #fff;
  cursor: pointer;
}

.page-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.page-button.active {
  background-color: #f31986;
  font-weight: bold;
}

.page-ellipsis {
  cursor: default;
  background-color: transparent;
  color: #000;
}

@media (max-width: 576px) {

  .page-button,
  .page-ellipsis {
    padding: 0.3rem 0.5rem;
    font-size: 0.8rem;
  }
}

@media (max-width: 400px) {

  .page-button,
  .page-ellipsis {
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
  }
}

.page-button:hover:not(:disabled),
.page-button:focus:not(:disabled) {
  background-color: #e00074;
  outline: none;
}

.icon-image {
  width: 40px;
  /* Adjust the size as needed */
  height: auto;
}

.remaining-image {
  width: 80px;
  /* Adjust the size as needed */
  height: auto;
}

.credit-detail {
  margin-bottom: 1rem;
}

.line-G {
  border-top: 1px solid #ccc;
  /* Adjust the line style as needed */
}

/* Responsive adjustments if necessary */
@media (max-width: 576px) {
  .credit-detail {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .remaining-image {
    width: 60px;
    /* Adjust the size as needed */
    height: auto;
  }


  .logo-column {
    margin-bottom: 0.5rem;
  }
}

/* Hide breadcrumb by default */
.breadcrumb-mobile {
  display: none;
}

/* Show only for screens 768px or smaller */
@media screen and (max-width: 768px) {
  .breadcrumb-mobile {
    display: block;
  }
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;
}
</style>
