<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <div class="card shadow rounded-5 hborder pe-0 ps-1 pt-0 pb-0">
      <div class="card-body pt-0">
        <div class="row justify-content-center pt-2">
          <div class="col col-sm-6 text-center">
            <div v-if="hasError" class="alert alert-danger p-2"> {{ message }} </div>
          </div>
        </div>
        <!--begin:breadcrumb-->
        <div class="row pt-2 pb-3">
          <div class="col ps-3">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  <router-link to="/brandview"><img src="/assets/icons/back-arrow.svg" alt="icon"
                      class="icon-class-bg" /></router-link>
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <h4 class="text-center mb-3 text-uppercase font-bold">EDIT YOUR BRAND</h4>
        <!--end:breadcrumb-->
        <!-- Loader -->
        <div v-if="loading" class="row">
          <div class="col text-center">
            <loadersoda />
          </div>
        </div>
        <!-- Form content -->
        <div v-else class="row justify-content-center">
          <div class="col-12 col-sm-6">
            <form @submit.prevent="updateSubmit()">
              <!-- Accordion: Brand Info (Mandatory Fields) -->
              <div class="accordion formB" id="accordionExample">
                <div class="accordion-item bg-gray">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button formB" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <i class="fa fa-tag font-color me-3"></i> Brand Main Info <span class="text-danger">*</span>
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse hide" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <!-- Brand Name (Mandatory) -->
                      <div class="row pt-2">
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <label for="">Brand Name <span class="text-danger">*</span></label>
                              <input type="text" v-model="name" class="form-control" required>
                            </div>
                          </div>
                          <!-- Categories (Mandatory) -->
                          <div class="row pt-2">
                            <div class="col pt-2">
                              <label for="">Categories <span class="text-danger">*</span></label>
                              <select v-model="categories" @change="handleCategoryChange" class="form-select" required>
                                <option v-for="(row, index) in rows" :key="index" :value="row.id">{{
                                  row.title }}</option>
                                <option value="others">Other</option>
                              </select>
                            </div>
                          </div>
                          <!-- Subcategories (Conditional) -->
                          <div v-if="categories !== 'others'" class="row pt-2">
                            <div class="col pt-2">
                              <label for="">Subcategories <span class="text-danger">*</span></label>
                              <multiselect v-model="subcategories" :options="items" placeholder="Select subcategories"
                                label="title" track-by="id" :multiple="true" :close-on-select="false"
                                :clear-on-select="false" :hide-selected="true" class="form-control" />
                            </div>
                          </div>
                          <!-- New Category (Only show when "Other" is selected) -->
                          <div v-if="categories === 'others'" class="row pt-2">
                            <div class="col pt-2">
                              <label for="">Other Categories <span class="text-danger">*</span></label>
                              <input type="text" v-model="otherCategories" class="form-control" required
                                placeholder="Please specify">
                            </div>
                          </div>
                          <!-- Target Location -->
                          <div class="row pt-2 pb-3">
                            <div class="col">
                              <label for="interests">Target Location <span class="text-danger">*</span></label>
                              <multiselect v-model="selectedCountry" :options="countries" :searchable="true"
                                :close-on-select="true" :clear-on-select="false" placeholder="Search for a country"
                                :show-labels="false" track-by="name" label="name">
                              </multiselect>
                            </div>
                          </div>
                          <div class="row pt-2">
                            <div class="col pt-2">
                              <label for="">Brand Logo <span class="text-danger">*</span></label>
                              <small class="text-muted d-block">Maximum Dimensions: 1920x1920 pixels, Maximum Size:
                                15MB</small>
                              <div style="position: relative;">
                                <div class="form-img pt-2"><i class="fa-solid fa-cloud-arrow-up font-26 pe-2"></i> Drag
                                  a
                                  image here or click to choose a file.</div>
                                <DropZone class="border-D" @addedFile="onFileAdd" message="Upload a file"
                                  :maxFiles="Number(3)" :maxFileSize="15000000" :uploadOnDrop="false"
                                  :multipleUpload="true" :parallelUpload="3" />
                              </div>



                              <div class="row justify-content-center align-items-center align-content-center">
                                <div class="col-6 col-sm-4 col-md-3 pt-3 imagebox">
                                  <div class="image-container-box position-relative">
                                    <img class="img-fluid-box" :src="BASE_URL + logoURL" alt="Image">
                                  </div>
                                </div>
                              </div>




                            </div>
                          </div>
                          <div class="row pt-5">
                            <div class="col">
                              <label for="" class="pe-4">Brand Primary Color:</label>
                              <color-picker v-model:pureColor="primary_color" />
                            </div>
                            <div class="col">
                              <label for="" class="pe-4">Brand Secondary Color:</label>
                              <color-picker v-model:pureColor="secondary_color" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:brandInfo-->
              <!--begin:Contact Information-->
              <div class="accordion formB pt-2" id="accordionBrandIdentity">
                <div class="accordion-item bg-gray">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button bg-gray" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                      <i class="fa fa-phone font-color me-3"></i> Contacts Information
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingOne"
                    data-bs-parent="#accordionBrandIdentity">
                    <div class="accordion-body">
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Phone Number</label>
                          <input type="text" v-model="phone" class="form-control">
                        </div>
                        <div class="col">
                          <label for="">Other Phone</label>
                          <input type="text" v-model="otherphone" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">
                            Whatsapp Phone Number</label>
                          <input type="text" v-model="whatsApp" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Brand Email</label>
                          <input type="email" v-model="email" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Brand Website</label>
                          <input type="text" v-model="website" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Brand Address</label>
                          <input type="text" v-model="address" class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:Contact Information-->
              <!--begin:Brand Marketing Details-->
              <div class="accordion formB pt-2" id="accordionBrandMarketing">
                <div class="accordion-item bg-gray">
                  <h2 class="accordion-header" id="headingBrandMarketing">
                    <button class="accordion-button bg-gray" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseBrandMarketing" aria-expanded="true"
                      aria-controls="collapseBrandMarketing">
                      <i class="fa fa-lightbulb font-color me-3"></i> Brand Marketing Details
                    </button>
                  </h2>
                  <div id="collapseBrandMarketing" class="accordion-collapse collapse"
                    aria-labelledby="headingBrandMarketing" data-bs-parent="#accordionBrandMarketing">
                    <div class="accordion-body">
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Brand Slogan</label>
                          <input type="text" v-model="slogan" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Opening Date</label>
                          <input type="date" v-model="opening_date" class="form-control">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for="">Unique Selling Point</label>
                          <input type="text" v-model="unique_selling_point" class="form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:Brand Marketing Details-->
              <!--begin:mediaLinks-->
              <div class="accordion formB pt-2" id="accordionMediaLinks">
                <div class="accordion-item bg-gray">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button formB" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <i class="fa fa-globe font-color me-3"></i> Social Media Links
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                    data-bs-parent="#accordionMediaLinks">
                    <div class="accordion-body">
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-facebook font-color"></i> Facebook</label>
                          <input type="text" v-model="facebook" placeholder="LINK" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-twitter font-color"></i> X</label>
                          <input type="text" v-model="twitter" placeholder="LINK" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-instagram font-color"></i> Instagram</label>
                          <input type="text" v-model="instagram" placeholder="USER" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-snapchat font-color"></i> Snapchat</label>
                          <input type="text" v-model="snapchat" placeholder="USER" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-tiktok font-color"></i> TikTok</label>
                          <input type="text" v-model="tiktok" placeholder="USER" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-linkedin font-color"></i> LinkedIn</label>
                          <input type="text" v-model="linkedin" placeholder="LINK" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-behance font-color"></i> Behance</label>
                          <input type="text" v-model="behance" placeholder="LINK" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-youtube font-color"></i> Youtube</label>
                          <input type="text" placeholder="LINK" v-model="youtube" class="form-control font-14">
                        </div>
                      </div>
                      <div class="row pt-2">
                        <div class="col">
                          <label for=""><i class="fa-brands fa-pinterest font-color"></i> Pinterest</label>
                          <input type="text" v-model="pinterest" placeholder="LINK" class="form-control font-14">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:mediaLinks-->
              <!--begin:Target_Audience-->
              <div class="accordion formB pt-2" id="accordionTarget">
                <div class="accordion-item bg-gray">
                  <h2 class="accordion-header" id="headingAudience">
                    <button class="accordion-button bg-gray" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTarget_Audience" aria-expanded="true" aria-controls="collapseTwo">
                      <i class="fa fa-bullseye font-color me-3"></i> Target Audience
                    </button>
                  </h2>
                  <div id="collapseTarget_Audience" class="accordion-collapse collapse hide"
                    aria-labelledby="headingAudience" data-bs-parent="#accordionTarget">
                    <div class="accordion-body">

                      <div class="row">
                        <div class="col">
                          <label for="ageFrom">Age (From)</label>
                          <select v-model.number="ageFrom" class="form-select">
                            <option v-for="n in 48" :key="n" :value="n + 17">{{ n + 17 }}</option>
                          </select>
                        </div>
                        <div class="col">
                          <label for="ageTo">Age (To)</label>
                          <select v-model.number="ageTo" class="form-select">
                            <option v-for="n in 48" :key="n" :value="n + 17">{{ n + 17 }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row pt-3">
                        <div class="col">

                          <label class="me-3">Gender:</label>

                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="gender-all" v-model="gender" value="all" />
                            <label class="form-check-label" for="gender-all">All</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="gender-male" v-model="gender"
                              value="male" />
                            <label class="form-check-label" for="gender-male">Male</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" id="gender-female" v-model="gender"
                              value="female" />
                            <label class="form-check-label" for="gender-female">Female</label>
                          </div>
                        </div>
                      </div>
                      <!-- Interests -->
                      <div class="row pt-2">
                        <div class="col">
                          <label for="interests">Interests</label>
                          <vue3-tags-input v-if="!loading && interests !== null" :tags="interests"
                            placeholder="Add Your Interests" @on-tags-changed="handleChangeInterestTags" />
                        </div>
                      </div>
                      <!-- Behaviors -->
                      <div class="row pt-2">
                        <div class="col">
                          <label for="behaviors">Behaviors</label>
                          <vue3-tags-input v-if="!loading && behaviors !== null" :tags="behaviors"
                            placeholder="Add Your Behaviors" @on-tags-changed="handleChangeBehaviorTags" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end:Target_Audience-->
              <div class="row pt-2" v-if="createB">
                <div class="col text-center">
                  <loader />
                </div>
              </div>
              <div v-else class="row pt-3">
                <div class="col text-end pb-3">
                  <button type="submit" class="btn bg-button px-5">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DropZone } from 'dropzone-vue';
import Multiselect from 'vue-multiselect';
import rgbHex from 'rgb-hex';
import Vue3TagsInput from 'vue3-tags-input';
import globalErrorHandler from "@/globalErrorHandler";


export default {
  name: 'updatebrand',
  components: {
    DropZone,
    Multiselect,
    Vue3TagsInput
  },
  data() {
    return {
      brand_id: this.$route.params.id,
      hasError: false,
      message: "",
      name: "",
      row: {},
      website: "",
      phone: "",
      otherphone: "",
      whatsApp: "",
      email: "",
      address: "",
      imagelogo: "",
      primary_color: "",
      secondary_color: "",
      categories: 0,
      subcategories: [],
      slogan: "",
      opening_date: "",
      unique_selling_point: "",
      facebook: "",
      twitter: "",
      instagram: "",
      snapchat: "",
      tiktok: "",
      linkedin: "",
      behance: "",
      youtube: "",
      pinterest: "",
      rows: [],
      items: [],
      otherCategories: "",
      loading: true,
      createB: false,
      logoURL: "",
      ageFrom: 18,
      ageTo: 65,
      gender: 'all',
      interests: null,
      behaviors: null,
      selectedCountry: null,
      countries: [

        { name: 'Algeria' },
        { name: 'Bahrain' },
        { name: 'Comoros' },
        { name: 'Djibouti' },
        { name: 'Egypt' },
        { name: 'Iraq' },
        { name: 'Jordan' },
        { name: 'Kuwait' },
        { name: 'Lebanon' },
        { name: 'Libya' },
        { name: 'Mauritania' },
        { name: 'Morocco' },
        { name: 'Oman' },
        { name: 'Palestine' },
        { name: 'Qatar' },
        { name: 'Saudi Arabia' },
        { name: 'Somalia' },
        { name: 'Sudan' },
        { name: 'Syria' },
        { name: 'Tunisia' },
        { name: 'United Arab Emirates' },
        { name: 'Yemen' },
        { name: 'Afghanistan' },
        { name: 'Albania' },
        { name: 'Andorra' },
        { name: 'Angola' },
        { name: 'Antigua and Barbuda' },
        { name: 'Argentina' },
        { name: 'Armenia' },
        { name: 'Australia' },
        { name: 'Austria' },
        { name: 'Azerbaijan' },
        { name: 'Bahamas' },
        { name: 'Bangladesh' },
        { name: 'Barbados' },
        { name: 'Belarus' },
        { name: 'Belgium' },
        { name: 'Belize' },
        { name: 'Benin' },
        { name: 'Bhutan' },
        { name: 'Bolivia' },
        { name: 'Bosnia and Herzegovina' },
        { name: 'Botswana' },
        { name: 'Brazil' },
        { name: 'Brunei' },
        { name: 'Bulgaria' },
        { name: 'Burkina Faso' },
        { name: 'Burundi' },
        { name: 'Cabo Verde' },
        { name: 'Cambodia' },
        { name: 'Cameroon' },
        { name: 'Canada' },
        { name: 'Central African Republic' },
        { name: 'Chad' },
        { name: 'Chile' },
        { name: 'China' },
        { name: 'Colombia' },
        { name: 'Congo (Congo-Brazzaville)' },
        { name: 'Costa Rica' },
        { name: 'Croatia' },
        { name: 'Cuba' },
        { name: 'Cyprus' },
        { name: 'Czech Republic' },
        { name: 'Democratic Republic of the Congo' },
        { name: 'Denmark' },
        { name: 'Dominica' },
        { name: 'Dominican Republic' },
        { name: 'Ecuador' },
        { name: 'El Salvador' },
        { name: 'Equatorial Guinea' },
        { name: 'Eritrea' },
        { name: 'Estonia' },
        { name: 'Eswatini' },
        { name: 'Ethiopia' },
        { name: 'Fiji' },
        { name: 'Finland' },
        { name: 'France' },
        { name: 'Gabon' },
        { name: 'Gambia' },
        { name: 'Georgia' },
        { name: 'Germany' },
        { name: 'Ghana' },
        { name: 'Greece' },
        { name: 'Grenada' },
        { name: 'Guatemala' },
        { name: 'Guinea' },
        { name: 'Guinea-Bissau' },
        { name: 'Guyana' },
        { name: 'Haiti' },
        { name: 'Honduras' },
        { name: 'Hungary' },
        { name: 'Iceland' },
        { name: 'India' },
        { name: 'Indonesia' },
        { name: 'Iran' },
        { name: 'Ireland' },
        { name: 'Italy' },
        { name: 'Ivory Coast' },
        { name: 'Jamaica' },
        { name: 'Japan' },
        { name: 'Kazakhstan' },
        { name: 'Kenya' },
        { name: 'Kiribati' },
        { name: 'Kosovo' },
        { name: 'Kyrgyzstan' },
        { name: 'Laos' },
        { name: 'Latvia' },
        { name: 'Lesotho' },
        { name: 'Liberia' },
        { name: 'Liechtenstein' },
        { name: 'Lithuania' },
        { name: 'Luxembourg' },
        { name: 'Madagascar' },
        { name: 'Malawi' },
        { name: 'Malaysia' },
        { name: 'Maldives' },
        { name: 'Mali' },
        { name: 'Malta' },
        { name: 'Marshall Islands' },
        { name: 'Mexico' },
        { name: 'Micronesia' },
        { name: 'Moldova' },
        { name: 'Monaco' },
        { name: 'Mongolia' },
        { name: 'Montenegro' },
        { name: 'Mozambique' },
        { name: 'Myanmar (Burma)' },
        { name: 'Namibia' },
        { name: 'Nauru' },
        { name: 'Nepal' },
        { name: 'Netherlands' },
        { name: 'New Zealand' },
        { name: 'Nicaragua' },
        { name: 'Niger' },
        { name: 'Nigeria' },
        { name: 'North Korea' },
        { name: 'North Macedonia' },
        { name: 'Norway' },
        { name: 'Pakistan' },
        { name: 'Palau' },
        { name: 'Panama' },
        { name: 'Papua New Guinea' },
        { name: 'Paraguay' },
        { name: 'Peru' },
        { name: 'Philippines' },
        { name: 'Poland' },
        { name: 'Portugal' },
        { name: 'Romania' },
        { name: 'Russia' },
        { name: 'Rwanda' },
        { name: 'Saint Kitts and Nevis' },
        { name: 'Saint Lucia' },
        { name: 'Saint Vincent and the Grenadines' },
        { name: 'Samoa' },
        { name: 'San Marino' },
        { name: 'Sao Tome and Principe' },
        { name: 'Senegal' },
        { name: 'Serbia' },
        { name: 'Seychelles' },
        { name: 'Sierra Leone' },
        { name: 'Singapore' },
        { name: 'Slovakia' },
        { name: 'Slovenia' },
        { name: 'Solomon Islands' },
        { name: 'South Africa' },
        { name: 'South Korea' },
        { name: 'South Sudan' },
        { name: 'Spain' },
        { name: 'Sri Lanka' },
        { name: 'Suriname' },
        { name: 'Sweden' },
        { name: 'Switzerland' },
        { name: 'Tajikistan' },
        { name: 'Tanzania' },
        { name: 'Thailand' },
        { name: 'Timor-Leste' },
        { name: 'Togo' },
        { name: 'Tonga' },
        { name: 'Trinidad and Tobago' },
        { name: 'Tunisia' },
        { name: 'Turkey' },
        { name: 'Turkmenistan' },
        { name: 'Tuvalu' },
        { name: 'Uganda' },
        { name: 'Ukraine' },
        { name: 'United Kingdom' },
        { name: 'United States' },
        { name: 'Uruguay' },
        { name: 'Uzbekistan' },
        { name: 'Vanuatu' },
        { name: 'Vatican City' },
        { name: 'Venezuela' },
        { name: 'Vietnam' },
        { name: 'Zambia' },
        { name: 'Zimbabwe' }
      ]

    }
  },
  computed: {
    ...mapGetters(['BASE_URL']),
  },
  methods: {
    handleChangeInterestTags(newTags) {
      this.interests = newTags;
    },

    handleChangeBehaviorTags(newTags) {
      this.behaviors = newTags;
    },

    getData() {
      this.$http.get("/api/subscriptions/brands/" + this.brand_id + '/').then(res => {
        this.row = res.data.brand;
        this.name = res.data.brand.name;
        this.logoURL = res.data.brand.logo;
        this.categories = res.data.brand.categories || 'others';
        this.slogan = res.data.brand.slogan;
        this.opening_date = res.data.brand.opening_date;
        this.website = res.data.brand.website;
        this.email = res.data.brand.email;
        this.phone = res.data.brand.phone;
        this.otherphone = res.data.brand.other_phone;
        this.address = res.data.brand.address;
        this.whatsApp = res.data.brand.whatsapp;
        this.unique_selling_point = res.data.brand.unique_selling_point;
        this.ageFrom = res.data.brand.age_from;
        this.ageTo = res.data.brand.age_to;
        this.gender = res.data.brand.gender;
        this.interests = res.data.brand.interests || [];
        this.behaviors = res.data.brand.behaviors || [];
        this.otherCategories = res.data.brand.other_category || '';
        // Social Links
        this.facebook = res.data.brand.facebook;
        this.instagram = res.data.brand.instagram;
        this.youtube = res.data.brand.youtube;
        this.tiktok = res.data.brand.tiktok;
        this.linkedin = res.data.brand.linkedin;
        this.snapchat = res.data.brand.snapchat;
        this.twitter = res.data.brand.x_link;
        this.pinterest = res.data.brand.pinterest;
        this.behance = res.data.brand.behance;
        const locationName = res.data.brand.location;
        this.selectedCountry = this.countries.find(country => country.name === locationName) || null;

        if (res.data.brand.primary_color) {
          this.primary_color = res.data.brand.primary_color;
        }
        if (res.data.brand.secondary_color) {
          this.secondary_color = res.data.brand.secondary_color;
        }

        // Fetch subcategories based on the selected category
        if (this.categories !== 'others') {
          this.subcategories = res.data.brand.subcategories;
          this.fetchSubcategoryDetails(this.categories);  // Load subcategories
        }

        this.Categories();
        this.loading = false;
      }).catch(err => {
        this.hasError = true;
        this.message = globalErrorHandler(err.data)[0];
        window.scroll(0, 0);
      });
    },

    fetchSubcategoryDetails(cat_id) {
      if (cat_id) {
        this.$http.get(`/api/settings/subcategories/${cat_id}/`).then(res => {
          if (res.data.status) {
            // Populate multiselect with full objects
            this.items = res.data.subcategories;

            // Map subcategory IDs to the corresponding subcategory objects
            this.subcategories = res.data.subcategories.filter(subcategory =>
              this.subcategories.includes(subcategory.id)
            );
          }
        }).catch(err => {
          this.hasError = true;
          this.message = globalErrorHandler(err.data)[0];
          window.scroll(0, 0);
        });
      }
    },


    // Fetch subcategories based on the selected category (cat_id)
    fetchSubcategories(cat_id) {
      if (cat_id) {
        this.$http.get(`/api/settings/subcategories/${cat_id}/`).then(res => {
          if (res.data.status) {
            // Load the subcategories as options for the multiselect
            this.items = res.data.subcategories;
          } else {
            console.error("Failed to load subcategories.");
          }
        }).catch(err => {
          this.hasError = true;
          this.message = globalErrorHandler(err.data)[0];
          window.scroll(0, 0);
        });
      }
    },
    // Update brand data submission
    updateSubmit() {
      this.createB = true;
      const formData = new FormData();
      formData.append('name', this.name);
      formData.append('logo', this.imagelogo);

      if (this.categories === 'others') {
        formData.append('categories', "");  // No categories selected
        formData.append('other_category', this.otherCategories);  // Custom category
      } else {
        formData.append('categories', this.categories);
        formData.append('other_category', '');  // Reset otherCategories to null

        // Map subcategory objects to an array of IDs (numbers)
        const subcategoryIds = this.subcategories.map(subcategory => subcategory.id);
        formData.append('subcategories', subcategoryIds);  // Send the IDs, not strings or objects
      }
      if (this.selectedCountry && this.selectedCountry.name) {
        formData.append('location', this.selectedCountry.name);
      }
      formData.append('slogan', this.slogan);
      formData.append('opening_date', this.opening_date);
      formData.append('website', this.website);
      formData.append('email', this.email);
      formData.append('phone', this.phone);
      formData.append('other_phone', this.otherphone);
      formData.append('whatsapp', this.whatsApp);
      formData.append('address', this.address);
      formData.append('unique_selling_point', this.unique_selling_point);

      // Handle primary and secondary color
      if (this.primary_color.indexOf('rgb') != -1) {
        formData.append('primary_color', '#' + rgbHex(this.primary_color));
      } else {
        formData.append('primary_color', this.primary_color);
      }
      if (this.secondary_color.indexOf('rgb') != -1) {
        formData.append('secondary_color', '#' + rgbHex(this.secondary_color));
      } else {
        formData.append('secondary_color', this.secondary_color);
      }
      formData.append('age_from', this.ageFrom.toString());
      formData.append('age_to', this.ageTo.toString());
      formData.append('gender', this.gender);
      formData.append('interests', JSON.stringify(this.interests));
      formData.append('behaviors', JSON.stringify(this.behaviors));

      // Social Links
      formData.append('facebook', this.facebook);
      formData.append('instagram', this.instagram);
      formData.append('tiktok', this.tiktok);
      formData.append('snapchat', this.snapchat);
      formData.append('youtube', this.youtube);
      formData.append('linkedin', this.linkedin);
      formData.append('x_link', this.twitter);
      formData.append('behance', this.behance);
      formData.append('pinterest', this.pinterest);

      // Send the form data via PUT request
      this.$http.put('/api/subscriptions/brands/update/' + this.brand_id + '/', formData).then(res => {
        this.createB = false;
        if (res.status === 200) {
          this.$router.push({ path: "/brandview" });
        }
      }).catch(err => {
        this.hasError = true;
        this.message = globalErrorHandler(err.data)[0];
        this.createB = false;
        window.scroll(0, 0);
      });
    },

    // Fetch categories
    Categories() {
      this.$http.get("/api/settings/categories/").then(res => {
        this.rows = res.data.categories;
      }).catch(err => {
        this.hasError = true;
        this.message = globalErrorHandler(err.data)[0];
        window.scroll(0, 0);
      });
    },

    // When the category changes, fetch the subcategories
    handleCategoryChange() {
      if (this.categories === 'others') {
        this.subcategories = [];
        this.items = [];
      } else {
        this.otherCategories = null;  // Reset the otherCategories to null
        this.fetchSubcategories(this.categories);
      }
    },

    // Handle file uploads
    onFileAdd(e) {
      this.imagelogo = e.file;
    },
  },
  created() {
    this.getData();
  }
};
</script>

<style scoped>
span.multiselect__tag {
  background: #f70081 !important;
}

.multiselect {
  width: 96%;
}

span.v3ti-tag {
  background: #f70081 !important;
}

.v3ti .v3ti-tag .v3ti-remove-tag {
  color: #000000 !important;
  transition: color .3s !important;
}

.v3ti .v3ti-tag .v3ti-remove-tag:hover {
  color: #ffffff !important;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

.icon-class-bg {
  width: 30px;
  height: 30px;
  margin-left: 5px;
}


.imagebox {
  position: relative;
}

.image-container-box {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 150%;
  border-radius: 8px;
}

.img-fluid-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 15px;
}
</style>