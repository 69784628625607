<template>
  <!-- Header Section -->
  <div class="container-fluid bgcustom pt-0 pb-0 mt-0 mb-5">
    <div class="card shadow rounded-5 hborder mx-auto checkout-container" style="max-width: 1200px;">
      <!-- Logo Section -->
      <div class="text-center">
        <img src="/assets/images/logo-soda.svg" class="logo-width mt-3" alt="Company Logo" />
      </div>
      <div class="flex-grow-1 text-center mt-5 d-none d-md-block mb-2">
        <h3 class="fw-bold mb-3 p-2 text-danger ">Access All Features – Upgrade to Keep Generating Stunning Content</h3>
      </div>
      <!-- Error Display -->
      <div v-if="errors.length" class="alert alert-danger mx-4 text-center p-1 br-radius">
        <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
      </div>
      <div class="card-body text-center">
        <!-- Error or success message -->
        <div v-if="message" class="alert alert-info container w-50">{{ message }}</div>
      </div>
      <!-- Checkout Content -->
      <div class="row p-2">
        <!-- Left Column: Plan Details -->
        <div class="col-md-6 col-sm-12 mb-4 d-flex flex-column justify-content-center">
          <div class="plan-details">
            <!-- Inside the plan-details div -->
            <h3 class="plan-title">Subscribe To: {{ planDetails?.name || 'Loading...' }} Plan</h3>
            <p class="plan-price mb-2">
              <span class="currency-symbol"></span>
              <span v-if="isPromoCodeValid">
                <del class="original-price">${{ formattedPriceValue }}</del>
                <span class="currency-symbol"></span>${{ displayedPrice }}
              </span>
              <span v-else>
                {{ formattedPriceValue }}
              </span>
              <span class="billing-cycle">/ {{ billingCycleLabel }}</span>
            </p>

            <!-- Inside the promo-code-section -->
            <div class="promo-code-section text-md-start text-center">
              <template v-if="!showPromoInput">
                <button class="btn btn-link add-promo-code-btn " @click="showPromoInput = true">Add Promotion
                  Code</button>
              </template>
              <template v-else>
                <div class="promo-code-input-group">
                  <input type="text" v-model="promoCode" placeholder="Promotion Code"
                    class="form-control promo-code-input" />
                  <button class="btn btn-primary apply-promo-code-btn" @click="applyPromoCode">Apply</button>
                </div>
                <!-- Display the promo code message -->
                <p v-if="promoCodeMessage"
                  :class="{ 'promo-code-success': isPromoCodeValid, 'promo-code-error': isPromoCodeValid === false }"
                  class="promo-code-message">
                  {{ promoCodeMessage }}
                </p>
              </template>
            </div>
            <p class="pay-now-info" v-if="isPromoCodeValid">
              Then<strong> ${{ formattedPriceValue }} </strong> per month after coupon expires.
            </p>
            <!-- Updated Features List -->
            <ul class="list-unstyled plan-features lh-lg d-none d-md-block">
              <li v-for="(feature, index) in planDetails?.features" :key="index" class="feature-item">
                <i class="fa-solid fa-check iconcolor"></i> {{ feature.name }}
              </li>
            </ul>

          </div>
        </div>
        <!-- Right Column: Payment Form -->
        <div class="col-md-6 col-sm-12">
          <!-- Show loading indicator while processing -->
          <div v-if="isLoading" class="text-center">
            <loader />
          </div>
          <div class="payment-form-container">
            <h4 class="mb-4 text-center">Enter Your Payment Details</h4>
            <PaymentForm :thenPromising="handleSubmit" />
          </div>
        </div>
        <div class="text-center mt-5">
          <img src="/assets/images/stripe.png" class="logo-widths mt-3" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentForm from "@/views/Billing/PaymentForm.vue";
import { fetchPlans, upgradeSubscription, validatePromoCode, getPriceAfterCoupon } from "@/apis";
import { mapGetters } from "vuex";
import globalErrorHandler from "@/globalErrorHandler";

export default {
  name: "Checkout",
  data() {
    return {
      planDetails: null,
      errors: [],
      planId: this.$route.params.id,
      isLoading: false,
      showPromoInput: false,
      promoCode: '',
      promoCodeMessage: '',
      message: '',
      isPromoCodeValid: false,
      originalPrice: 0,
      discountedPrice: 0,
      formattedPriceValue: '0.00', // Renamed to avoid conflict
      displayedPrice: '0.00',
      selectedPricingId: null, // Added
    };
  },
  components: {
    PaymentForm,
  },
  computed: {
    ...mapGetters(['GET_BASE_SUBSCRIPTION']),
    ...mapGetters(['GET_DATA_USER']),
    billingCycleLabel() {
      const selectedPricing = this.planDetails?.pricing.find(p => p.id === parseInt(this.planId, 10));
      return selectedPricing?.interval === 'year' ? 'year' : 'month';
    },
    planFeatures() {
      if (!this.planDetails) return [];
      const selectedPricing = this.planDetails.pricing.find(p => p.id === parseInt(this.planId, 10));
      return selectedPricing ? Object.entries(selectedPricing.features).map(([key, value]) => {
        return `${key.replace('_', ' ')}: ${value === true ? 'Yes' : value === false ? 'No' : value}`;
      }) : [];
    },
  },
  methods: {
    async applyPromoCode() {
      // Clear previous messages
      this.promoCodeMessage = '';
      this.isPromoCodeValid = null;

      if (!this.promoCode.trim()) {
        this.promoCodeMessage = 'Please enter a promotion code.';
        this.isPromoCodeValid = false;
        return;
      }

      try {
        // First, validate the promo code
        await validatePromoCode(this.promoCode);

        // Track GTM event for Apply Promo Code
        this.$gtm.trackEvent({
          event: "Apply Promo Code",
          category: "Checkout",
          action: "Apply Code",
          label: this.promoCode,
          is_valid: this.isPromoCodeValid,
        });


        // If no error, promo code is valid
        this.isPromoCodeValid = true;
        this.promoCodeMessage = 'Promotion code applied successfully!';

        // Now, get the price after coupon
        const pricingId = this.selectedPricingId; // Ensure this is set
        const adjustedPricing = await getPriceAfterCoupon(this.promoCode, pricingId);
        // Update the price using the adjusted pricing
        this.updatePriceWithPromoCode(adjustedPricing.amount);

      } catch (error) {
        // Handle error from backend
        this.isPromoCodeValid = false;
        this.promoCodeMessage = globalErrorHandler(error)[0] || 'An error occurred while applying the promotion code.';
      }
    },

    updatePriceWithPromoCode(adjustedPricing) {
      // discountedAmount is the new price after applying the coupon, in cents
      // Convert prices to dollars
      this.discountedPrice = adjustedPricing / 100;
      // this.originalPrice should already be set, in dollars
      this.displayedPrice = this.discountedPrice.toFixed(2);
      // formattedPriceValue remains the original price
      this.formattedPriceValue = this.originalPrice.toFixed(2);
    },

    async fetchPlanDetails() {
      try {
        const response = await fetchPlans();
        const plans = response.results.slice(1);

        const allPricings = plans.flatMap(plan => plan.pricing);
        const selectedPricing = allPricings.find(price => price.id === parseInt(this.planId, 10));

        if (selectedPricing) {
          this.planDetails = plans.find(plan => plan.pricing.includes(selectedPricing));
          this.selectedPricingId = selectedPricing.id; // Set selectedPricingId
          // Set originalPrice
          this.originalPrice = selectedPricing.amount / 100; // Convert from cents to dollars
          this.formattedPriceValue = this.originalPrice.toFixed(2);
          this.displayedPrice = this.formattedPriceValue; // Initially, displayed price is the original price
        } else {
          this.planDetails = null;
        }

      } catch (error) {
        this.errors = globalErrorHandler(error);
      }
    },

    async handleSubmit() {
      this.isLoading = true; // Activate loading state
      this.message = ""; // Clear any previous messages
      try {
        // Check if subscription ID is available
        const subscriptionId = this.GET_BASE_SUBSCRIPTION?.id;
        if (!subscriptionId) {
          throw new Error('No subscription ID available for resubscription.');
        }

        const payload = {
          pricing: this.planId,
          email: this.GET_DATA_USER.email,
          planName: this.planDetails.name
        };
        if (this.promoCode.trim()) {
          payload.code = this.promoCode;
        }
        // Call upgradeSubscription with the subscription ID and pricing
        await upgradeSubscription(subscriptionId, payload);

        // Track GTM event for Purchase
        this.$gtm.trackEvent({
          event: "Purchase",
          category: "Checkout",
          action: "Subscription",
          label: this.planDetails?.name || "Unknown Plan",
          value: parseFloat(this.displayedPrice), // Payment amount
          currency: "USD",
          plan: this.planDetails?.name,
          pricing_id: this.planId,
        });

        // Display success message and reset loading state
        this.message = "Your subscription has been successfully activated!";
        this.isLoading = false;

        // Redirect to home after a delay
        setTimeout(() => {
          this.$router.push({ path: '/billing' }).then(() => {
            window.location.reload();
          });
        }, 1000);
      } catch (error) {
        this.isLoading = false;
        const errors = globalErrorHandler(error);
        this.message = errors.length > 0 ? errors.join(" ") : "An unexpected error occurred.";
        console.error("Error during resubscription:", errors);
      }
    }
  },
  created() {
    this.fetchPlanDetails();
  },
};
</script>

<style scoped>
.card {
  border-radius: 17px;
}

.checkout-container {
  max-width: 1200px;
  margin-top: 20px;
  padding: 1rem;
}

.bgcustom {
  background-color: #f9f9f9;
}

.logo-width {
  width: 150px;
}

.logo-widths {
  width: 150px;
}

.plan-details {
  background-color: #f7f7f7;
  padding: 2rem;
  border-radius: 8px;
}

.plan-title {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.plan-price {
  font-size: 2rem;
  font-weight: bold;
  color: #ED3341;
}

.pay-now-info {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.currency-symbol {
  font-size: 1.5rem;
}

.billing-cycle {
  font-size: 1rem;
  color: #666;
}

.plan-features {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
  font-size: 1rem;
  color: #333;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  color: #333;
}

.iconcolor {
  color: #F21A61;
  margin-right: 8px;
}

.payment-form-container {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.alert-danger {
  border-radius: 8px;
  font-size: 0.9rem;
}

/* Tablet Responsiveness */
@media (max-width: 992px) {
  .checkout-container {
    max-width: 100%;
    padding: 1rem;
  }

  .plan-details {
    padding: 1.5rem;
  }

  .plan-price {
    font-size: 1.75rem;
  }

  .plan-title {
    font-size: 1.5rem;
  }

  .feature-item {
    font-size: 0.95rem;
  }

  .pay-now-info {
    font-size: 0.9rem;
  }

  .payment-form-container {
    padding: 1.5rem;
  }
}

/* Mobile Responsiveness */
@media (max-width: 767px) {
  .checkout-container {
    max-width: 100%;
    padding: 0;
  }

  .text-center {
    font-size: 16px;
    font-weight: bold;
  }

  .logo-widths {
    width: 150px;
    margin-top: -41px !important;
  }

  .row.p-5 {
    padding: 1rem;
  }

  .plan-details,
  .payment-form-container {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .plan-title {
    font-size: 1.3rem;
    text-align: center;
  }

  .plan-price {
    font-size: 1.5rem;
    text-align: center;
  }

  .billing-cycle {
    font-size: 0.9rem;
  }

  .pay-now-info {
    text-align: center;
  }

  .feature-item {
    font-size: 0.9rem;
  }

  .logo-width {
    width: 120px;
  }

  .text-danger {
    font-size: 1.2rem;
  }
}

.text-danger {
  color: #ED3341;
}


/* Promotion Code Section */
.promo-code-section {
  margin-bottom: 1rem;
}

.add-promo-code-btn {
  color: #007bff;
  text-decoration: underline;
  padding: 0;
  border: none;
  background: none;
  font-weight: bold;
}

.add-promo-code-btn:hover {
  text-decoration: none;
}

.promo-code-input-group {
  display: flex;
  align-items: center;
}

.promo-code-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.apply-promo-code-btn {
  margin-left: 0.5rem;
  background-color: #e26600;
  border-color: #e26600;
}

.apply-promo-code-btn:hover {
  background-color: #f31986;
  border-color: #f31986;
}

.promo-code-message {
  margin-top: 0.5rem;
}

.promo-code-success {
  color: #28a745;
  /* Green color for success */
}

.promo-code-error {
  color: #dc3545;
  /* Red color for error */
}

.original-price {
  color: #6c757d;
  /* Gray color */
  font-size: 17px;
  /* Slightly smaller font */
  margin-right: 5px;
}
</style>
