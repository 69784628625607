export default function globalErrorHandler(error) {
  let messages = [];
  let message;
  if (error?.response) {
    if (error.response?.status && error.response?.status === 500) {
      return ['500 Error: Please try again later'];
    }
    message = error.response.data;
  } else if (error?.data) {
    if (error.data?.status && error.data?.status === 500) {
      return ['500 Error: Please try again later'];
    }
    if (error?.data?.errors) {
      message = error.data.errors;
    } else if (error?.data?.error) {
      message = [error.data.error];
    } else {
      message = error.data;
    }
  } else if (error?.message) {
    message = [error.message];
  } else if (error) {
    if (typeof error === 'string') {
      message = [error];
    } else if (Array.isArray(error)) {
      message = error;
    } else if (typeof error === 'object') {
      Object.keys(error).forEach(key => {
        const value = error[key];
        if (typeof value === 'string') {
          message = [value];
        } else if (Array.isArray(value)) {
          message = [...value];
        }
      });
    } else {
      message = ['Unexpected error, Please Try Again Later.'];
    }
  } else {
    message = ['Unexpected error, Please Try Again Later.'];
  }

  // Process known error data structures
  if (typeof message === 'string') {
    messages.push(message);
  } else if (Array.isArray(message)) {
    messages = message;
  } else if (typeof message === 'object') {
    Object.keys(message).forEach(key => {
      const value = message[key];
      if (typeof value === 'string') {
        messages.push(value);
      } else if (Array.isArray(value)) {
        messages.push(...value);
      }
    });
  }
  return messages;
}