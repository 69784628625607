<template>
  <div class="card sticky-top">
    <div class="card-body">
      <div class="col col-sm-6 text-center">
        <div v-if="hasError" class="alert alert-danger p-2 text-center"> {{ message }} </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light px-1 cl-header">

        <router-link to="/">
          <img src="/assets/images/logo-soda.svg" class="logo-width" alt="">
        </router-link>

        <div class="btn-group position-relative d-lg-none d-none d-md-block position_Left">
          <!-- Button with Image and Dropdown Toggle -->
          <button class="btn btn-sm btn-generate dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <img src="/assets/images/Vector.png" class="images-width" alt="" />
            GENERATE
          </button>
          <!-- Dropdown Menu -->
          <ul class="dropdown-menu dropdown_Generate_md pt-0">
            <!-- Search Input -->
            <li class="dropdown-item p-2">
              <div class="input-group">
                <span class="input-group-text">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </span>
                <input class="form-control" v-model="searchKey" @keyup="sizeFilter()" type="search"
                  placeholder="Search" />
              </div>
            </li>
            <li>
              <hr class="dropdown-divider" />
            </li>

            <!-- Dropdown Items Tablets -->
            <li class="dropdown-item" v-for="(val, index) in sizeArray" :key="index" @click="changeSizes(val.id)">
              <div class="d-flex align-items-center">
                <img :src="BASE_URL + val.icon" class="images-width mx-1" alt="" />
                <div>
                  <span>{{ val.title }}</span> <br> <span class="dropdown-description">{{ val.description }}</span>
                  <br>
                </div>
              </div>
            </li>

          </ul>
        </div>

        <button class="navbar-toggler" id="mobNavBtn" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
          aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse pe-3" id="navbarTogglerDemo02">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 flex-column flex-lg-row pt-lg-0 bar_hidden">
            <li class="nav-item button-center text-center">
              <button @click="openUpgradeModal" class="btn billing-button mx-2 font-color">Upgrade</button>
            </li>
            <li class="nav-item">
              <img src="/assets/images/notifications-logo.png" class="notification-W mx-2" alt="">
            </li>
            <li class="nav-item button-center text-center">
              <div class="btn-group position-relative">
                <!-- Button with Image and Dropdown Toggle -->
                <button class="btn btn-sm btn-generate dropdown-toggle mx-3" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src="/assets/images/Vector.png" class="images-width" alt="" />
                  GENERATE
                </button>
                <!-- Dropdown Menu -->
                <ul class="dropdown-menu dropdown_Generate dropdown-generate pt-0">
                  <!-- Search Input -->
                  <li class="dropdown-item p-2">
                    <div class="input-group">
                      <span class="input-group-text">
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </span>
                      <input class="form-control" v-model="searchKey" @keyup="sizeFilter()" type="search"
                        placeholder="Search" />
                    </div>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>

                  <!-- Dropdown Items DESKTOP -->
                  <li class="dropdown-item" v-for="(val, index) in sizeArray" :key="index" @click="changeSizes(val.id)">
                    <div class="d-flex align-items-center">
                      <img :src="BASE_URL + val.icon" class="images-width mx-1" alt="" />
                      <span>{{ val.title }}</span> <br> <span class="dropdown-description">{{ val.description }}</span>
                      <br>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="nav-item button-center">
              <div class="btn-group">
                <img src="/assets/images/Profile-image.png" class="profile_icon mx-2 dropdown-toggle mouse"
                  data-bs-toggle="dropdown" aria-expanded="false" alt="">
                <ul class="dropdown-menu userProfile">
                  <li class="dropdown-item">
                    <div class="mx-2">{{ GET_DATA_USER.firstName }} {{ GET_DATA_USER.lastName }}</div>
                    <div class="mx-2">{{ GET_DATA_USER.email }}</div>
                  </li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <router-link to="/profile">
                    <li class="dropdown-item">
                      <div><i class="fa-regular fa-user me-2"></i>Profile</div>
                    </li>
                  </router-link>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li class="dropdown-item mouse" @click="methodlogOut()">
                    <i class="fa-solid fa-right-from-bracket p-color"></i>
                    Log Out
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <div class="row">
            <div class="col-6 d-lg-none">
              <!-- <MenuBar /> -->
              <div class="list-group list-group-flush left-menu mt-4 text-uppercase align-items-start">

                <div class="pb-2 links">
                  <router-link to="/" :class="('home'.indexOf(GET_ROUTE) != -1) ? 'active' : ''">
                    <img
                      :src="('home'.indexOf(GET_ROUTE) != -1) ? '/assets/images/Designs-Colors.png' : '/assets/images/Designs-Black.png'"
                      class="w-bubble" alt="">
                    <span class="link-name"> DESIGNS</span>
                  </router-link>
                </div>
                <div class=" pb-2 links">
                  <router-link to="/library" :class="('library'.indexOf(GET_ROUTE) != -1) ? 'active' : ''">
                    <img
                      :src="('library'.indexOf(GET_ROUTE) != -1) ? '/assets/images/Library-Colors.png' : '/assets/images/Library-Black.png'"
                      class="w-bubble" alt="">
                    <span class="link-name"> Library</span>
                  </router-link>
                </div>
                <div class=" pb-2 links">
                  <router-link to="/brandview"
                    :class="(['brandview', 'cbrand'].indexOf(GET_ROUTE) != -1) ? 'active' : ''">
                    <img
                      :src="('brandview'.indexOf(GET_ROUTE) != -1) ? '/assets/images/Brands-Colors.png' : '/assets/images/Brands-Black.png'"
                      class="w-bubble" alt="">
                    <span class="link-name"> Brands</span>
                  </router-link>
                </div>
                <div class="text-center pb-2 links">
                  <router-link to="/users" :class="(['users'].indexOf(GET_ROUTE) != -1) ? 'active' : ''">
                    <img
                      :src="('users'.indexOf(GET_ROUTE) != -1) ? '/assets/images/Users-Colors.png' : '/assets/images/Users-Black.png'"
                      class="w-bubble" alt="">
                    <span class="link-name"> Users</span>
                  </router-link>
                </div>
                <div v-if="access_Role == 'owner'" class="text-center pb-2 links">
                  <router-link to="/billing" :class="(['billing'].indexOf(GET_ROUTE) != -1) ? 'active' : ''">
                    <img
                      :src="('billing'.indexOf(GET_ROUTE) != -1) ? '/assets/images/Billing colors.png' : '/assets/images/Billing-Black.png'"
                      class="w-bubble" alt="">
                    <span class="link-name"> Billing</span>
                  </router-link>
                </div>

              </div>
            </div>
            <div class="col-6 d-lg-none text-md-center">
              <div class="btn-group mb-2 mt-4">
                <div class="pt-2">
                  <img src="/assets/images/notifications-logo.png" class="notification-W mx-2" alt="">
                </div>
                <img src="/assets/images/Profile-image.png"
                  class="profile_icon mx-2 dropdown-toggle mouse align-self-end" data-bs-toggle="dropdown"
                  aria-expanded="false" alt="">
                <ul class="dropdown-menu icon_Profile">
                  <li class="dropdown-item">
                    <div class="mx-2">{{ GET_DATA_USER.firstName }} {{ GET_DATA_USER.lastName }}</div>
                    <div class="mx-2">{{ GET_DATA_USER.email }}</div>
                  </li>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <router-link to="/profile">
                    <li class="dropdown-item">
                      <div><i class="fa-regular fa-user me-2"></i>Profile</div>
                    </li>
                  </router-link>
                  <li>
                    <hr class="dropdown-divider">
                  </li>
                  <li class="dropdown-item mouse" @click="methodlogOut()">
                    <i class="fa-solid fa-right-from-bracket p-color"></i>
                    Log Out
                  </li>
                </ul>
              </div>

              <div class="py-3">
                <button class=" btn billing-button mx-2 font-color" @click="openUpgradeModal">Upgrade</button>
              </div>
            </div>
          </div>
        </div>
        <div class="d-lg-none d-md-none pt-4">
          <div class="btn-group position-relative">
            <!-- Button with Image and Dropdown Toggle -->
            <button class="btn btn-sm btn-generate dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="/assets/images/Vector.png" class="images-width" alt="" />
              GENERATE
            </button>
            <!-- Dropdown Menu -->
            <ul class="dropdown-menu dropdown_Generate_sm pt-0">
              <!-- Search Input -->
              <li class="dropdown-item p-2">
                <div class="input-group">
                  <span class="input-group-text">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </span>
                  <input class="form-control" v-model="searchKey" @keyup="sizeFilter()" type="search"
                    placeholder="Search" />
                </div>
              </li>
              <li>
                <hr class="dropdown-divider" />
              </li>

              <!-- Dropdown Items MOBILE -->
              <li class="dropdown-item" v-for="(val, index) in sizeArray" :key="index" @click="changeSizes(val.id)">
                <div class="d-flex align-items-center">
                  <img :src="BASE_URL + val.icon" class="images-width mx-1" alt="" />
                  <span>{{ val.title }}</span> <br> <span class="dropdown-description">{{ val.description }}</span>
                  <br>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MenuBar from '@/components/MenuBar.vue';
import { onMounted } from 'vue';
import globalErrorHandler from "@/globalErrorHandler";

export default {
  name: 'NavBar',
  data() {
    return {
      sizeArray: [],
      searchKey: "",
      access_Role: localStorage.getItem('role'),
      hasError: false,
      message: '',

    }
  },
  computed: {
    ...mapGetters(['POST_SIZES', 'BASE_URL', 'GET_ROUTE', 'GET_DATA_USER']),
  },
  components: {
    MenuBar,
  },
  methods: {
    openUpgradeModal() {
      // Trigger the PlansModal component using $root
      this.$root.openPlansModal();
    },
    methodlogOut() {
      this.$http.get("/api/auth/logout/").then(res => {
        // console.log(res);
        this.$store.commit('REMOVE_USER_DATA');
        window.location.href = "/login";
        // this.$router.push({path: '/login'});
      }).catch(err => {
        this.hasError = true;
        this.message = globalErrorHandler(err.data)[0];
      })
    },
    changeSizes(key) {
      this.$router.push({ path: '/cdesign/' + key });
    },
    sizeFilter() {
      if (this.searchKey == "") {
        this.sizeArray = this.POST_SIZES;
        return;
      }
      var keyWord = this.searchKey.toLowerCase();
      var buffer = [];
      this.POST_SIZES.map((x) => {
        if (x.title.toLowerCase().search(keyWord) != -1) {
          buffer.push(x);
        }

      })
      this.sizeArray = buffer;
      // console.log(this.sizeArray);
      // console.log(keyWord);

    }
  },
  created() {
    this.sizeArray = this.POST_SIZES;

  },
  setup() {
    onMounted(() => {
      let navLinks = document.getElementsByClassName('navbar-close');
      for (let i = 0; i < navLinks.length; i++) {
        navLinks[i].addEventListener('click', () => {
          let navBtn = document.getElementById('mobNavBtn');
          if (navBtn) {
            navBtn.click();
          }
        });
      }
    });
  }
};
</script>

<style scoped>
.card {
  object-fit: center;
  min-height: 35px;
  margin-top: 15px !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* border-width: 1px; */
  /* border-radius: 17px; */
  /* border-color: #EDEBEBFF; */
}

.logo-width {
  width: 150px;
}

.card-body {
  padding: 0px;
}




.btn-group.position-relative .dropdown-menu.dropdown-generate {
  position: static;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  max-width: 90vw;
  z-index: 1000;
}

/* Adjust dropdown menu on larger screens */
@media (min-width: 768px) {
  .btn-group.position-relative .dropdown-menu.dropdown-generate {
    left: auto;
    position: absolute;
    transform: translateX(-50%);
    ;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .btn-group.position-relative .dropdown-menu.dropdown-generate {
    transform: translateX(-32%);
  }
}

@media (max-width: 767px) {
  .btn-group.position-relative .dropdown-menu.dropdown-generate {
    transform: translateX(-32%);
  }
}

@media (max-width: 480px) {

  .btn-group.position-relative .dropdown-menu.dropdown-generate {
    transform: translateX(-58%);
  }
}




/* Style for the input group */
.input-group-text {
  /* background-color: transparent; */
  border: none;
}

.input-group .form-control {
  border-left: none;
}

/* Style for images in the dropdown */
.images-width {
  width: 20px;
  height: auto;
}

/* Cursor pointer for dropdown items */
.d-flex.align-items-center {
  cursor: pointer;
}

/* Ensure the dropdown menu doesn't overflow */
.dropdown-menu.dropdown-generate {
  overflow-y: auto;
  max-height: 300px;
  /* Adjust as needed */
}

/* Styling for the description text under title with spacing */
.dropdown-description {
  font-size: 0.75rem;
  color: #6c757d;
  display: inline-block;
  margin-top: 2px;
  /* Adds space above the description */
  padding-left: 4px;
  /* Adds horizontal space before description text */
}

@media (max-width: 480px) {
  .dropdown-description {
    font-size: 10px;
    /* Adjust font size for smaller screens */
    padding-left: 2px;
    /* Adjust space before description text on smaller screens */
  }

  .dropdown-menu {
    font-size: 10px;

  }
}
</style>