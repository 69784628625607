<template>
  <div class="container-fluid bgcustom pt-3 pb-5 px-3 px-md-4">
    <div class="card shadow rounded-5 hborder p-3 p-md-4">
      <div class="card-body pt-0 mt-3 mt-md-4">
        <!-- Title Container -->
        <div class="title-container position-relative">
          <!-- Back Arrow Link -->
          <router-link to="/billing" class="back-arrow position-absolute top-50 start-0 translate-middle-y">
            <img src="/assets/icons/back-arrow.svg" alt="Back" class="icon-class-bg" />
          </router-link>

          <!-- Error Messages -->
          <div v-if="errors" class="alert alert-danger text-center p-1 br-radius">
            <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
          </div>

          <!-- Page Title -->
          <h3 class="fs-5 fs-md-4 mb-0">{{ type }} Subscriptions</h3>
        </div>
        <!-- Scrollable Table -->
        <div class="table-scroll-container">
          <div class="row" v-if="loading">
            <div class="col text-center">
              <loader />
            </div>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Start From</th>
                <th v-if="type !== 'Credits'">Valid Till</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sub in currentPageData" :key="sub.id">
                <td>{{ sub.display_name }}</td>
                <td>{{ sub.current_period_start }}</td>
                <td>{{ sub.current_period_end }}</td>
                <td>
                  <span :class="['badge', sub.status === 'active' ? 'badge-success' : 'badge-warning']">
                    {{ sub.status }}
                  </span>
                </td>
                <td>
                  <button v-if="sub.status === 'active'" class="btn btn-outline-primary btn-sm"
                    @click="openCancelModal(sub.id)">
                    Cancel
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="filteredSubscriptions.length === 0" class="text-center mt-4">
          <p>No subscriptions found for {{ type }}</p>
        </div>

        <!-- Pagination Controls -->
        <div class="pagination-container">
          <button :disabled="currentPage === 1" @click="goToPage(1)" class="page-button">
            First
          </button>
          <button :disabled="currentPage === 1" @click="goToPreviousPage" class="page-button">
            Previous
          </button>

          <!-- Ellipsis at the beginning -->
          <span v-if="visiblePageNumbers[0] > 2">
            <button @click="goToPage(1)" class="page-button">1</button>
            <span class="page-ellipsis">...</span>
          </span>
          <span v-else-if="visiblePageNumbers[0] === 2">
            <button @click="goToPage(1)" class="page-button">1</button>
          </span>

          <!-- Visible Page Numbers -->
          <button v-for="page in visiblePageNumbers" :key="page"
            :class="['page-button', { active: currentPage === page }]" @click="goToPage(page)">
            {{ page }}
          </button>

          <!-- Ellipsis at the end -->
          <span v-if="visiblePageNumbers[visiblePageNumbers.length - 1] < totalPages - 1">
            <span class="page-ellipsis">...</span>
            <button @click="goToPage(totalPages)" class="page-button">
              {{ totalPages }}
            </button>
          </span>
          <span v-else-if="visiblePageNumbers[visiblePageNumbers.length - 1] === totalPages - 1">
            <button @click="goToPage(totalPages)" class="page-button">
              {{ totalPages }}
            </button>
          </span>

          <button :disabled="currentPage === totalPages" @click="goToNextPage" class="page-button">
            Next
          </button>
          <button :disabled="currentPage === totalPages" @click="goToPage(totalPages)" class="page-button">
            Last
          </button>
        </div>
      </div>
    </div>
    <!-- Confirmation Modal -->
    <div v-if="showCancelModal" class="modal fade show d-block" tabindex="-1" style="background: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center p-4">
          <h5 class="text-primary mb-3">Confirm Cancellation</h5>
          <p>Are you sure you want to cancel this subscription?</p>
          <div class="d-flex justify-content-center gap-2">
            <button class="btn btn-secondary" @click="closeCancelModal">No, Keep it</button>
            <button class="btn btn-danger" @click="confirmCancel">Yes, Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listSubscriptions, cancelSubscription } from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";

export default {
  data() {
    return {
      subscriptions: [],
      currentPageData: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalPages: 0,
      totalRecords: 0,
      maxButtons: 5,
      type: this.$route.params.type,
      showCancelModal: false,
      subscriptionToCancel: null,
      errors: null,
      showSuccessPopup: false,
      loading: true,
    };
  },

  computed: {
    filteredSubscriptions() {
      return this.subscriptions.filter((sub) => sub.display_name === this.type);
    },
    visiblePageNumbers() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      const maxButtons = this.maxButtons;

      let startPage, endPage;

      if (totalPages <= maxButtons) {
        startPage = 1;
        endPage = totalPages;
      } else {
        const half = Math.floor(maxButtons / 2);
        if (currentPage <= half + 1) {
          startPage = 1;
          endPage = maxButtons;
        } else if (currentPage + half >= totalPages) {
          startPage = totalPages - maxButtons + 1;
          endPage = totalPages;
        } else {
          startPage = currentPage - half;
          endPage = currentPage + half;
        }
      }

      const pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    },
  },

  methods: {
    scrollHandler() {
      const tableContainer = this.$refs.tableContainer;
      this.showScrollIndicator =
        tableContainer.scrollLeft + tableContainer.clientWidth < tableContainer.scrollWidth;
    },
    async fetchSubscriptions() {
      this.loading = true;
      try {
        let url = null;
        let response;
        let allSubscriptions = [];
        let maxIterations = 100; // Safeguard to prevent infinite loops
        let iteration = 0;
        do {
          if (!url) {
            // Fetch the first page
            response = await listSubscriptions(1);
          } else {
            // Fetch the next page using the next URL
            response = await listSubscriptions(url);
          }
          allSubscriptions = allSubscriptions.concat(response.results);
          url = response.next;
          iteration++;

          // Break if no results are returned to prevent infinite loops
          if (!response.results || response.results.length === 0) {
            break;
          }

          // Safeguard: Break if iterations exceed maxIterations
          if (iteration >= maxIterations) {
            console.warn('Max iterations reached in fetchSubscriptions');
            break;
          }
        } while (url !== null);

        this.subscriptions = allSubscriptions;
        this.totalRecords = this.filteredSubscriptions.length;
        this.totalPages = Math.ceil(this.totalRecords / this.itemsPerPage);
        this.updateCurrentPageData();
        this.loading = false;
      } catch (error) {
        this.errors = globalErrorHandler(error.data);
      }
    },
    updateCurrentPageData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = this.currentPage * this.itemsPerPage;
      this.currentPageData = this.filteredSubscriptions.slice(startIndex, endIndex);
    },
    goToPage(page) {
      if (page !== this.currentPage) {
        this.currentPage = page;
        this.updateCurrentPageData();
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.updateCurrentPageData();
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.updateCurrentPageData();
      }
    },
    updateMaxButtons() {
      const width = window.innerWidth;
      if (width < 576) {
        this.maxButtons = 3;
      } else if (width < 768) {
        this.maxButtons = 5;
      } else if (width < 992) {
        this.maxButtons = 7;
      } else {
        this.maxButtons = 9;
      }
    },
    openCancelModal(id) {
      this.subscriptionToCancel = id;
      this.showCancelModal = true;
    },
    closeCancelModal() {
      this.showCancelModal = false;
      this.subscriptionToCancel = null;
    },
    async confirmCancel() {
      if (!this.subscriptionToCancel) return;

      try {
        await cancelSubscription(this.subscriptionToCancel, { cancellation_type: "at_period_end" });
        this.showCancelModal = false; // Hide the modal on success
        this.subscriptionToCancel = null; // Clear the stored ID
        this.fetchSubscriptions(); // Refresh the list to reflect changes
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
        }, 2000);
      } catch (error) {
        this.showCancelModal = false;
        this.subscriptionToCancel = null;
        this.errors = globalErrorHandler(error.data);
      }
    }
  },
  mounted() {
    this.updateMaxButtons();
    window.addEventListener('resize', this.updateMaxButtons);
    this.fetchSubscriptions();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMaxButtons);
  },
  created() {
    this.fetchSubscriptions();
  }
};
</script>

<style scoped>
.container-fluid {
  background-color: #ffffff;
}

.card {
  border-radius: 17px;
  background-color: #ffffff;
}

.title-container {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}

.back-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.icon-class-bg {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-md-4 {
  font-size: 1.5rem;
}

.table-responsive {
  overflow-x: auto;
}

.badge-success {
  background-color: #28a745;
  color: white;
}

.badge-warning {
  background-color: #E26600;
  color: white;
}

.btn-outline-primary {
  border-color: #E26600;
  color: #E26600;
  font-size: 0.875rem;
}

.btn-outline-primary:hover {
  background-color: #E26600;
  color: #ffffff;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 10px;
}

.modal.fade.show.d-block {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.text-warning-s {
  color: #E26600;
}

.table-scroll-container {
  overflow-x: auto;
  max-width: 100%;
}

/* Optional: Style tweaks for smoother scrolling experience */
.table-scroll-container::-webkit-scrollbar {
  height: 8px;
}

.table-scroll-container::-webkit-scrollbar-thumb {
  background-color: #f21764;
  border-radius: 4px;
}

.table-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}


.pagination-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.page-button,
.page-ellipsis {
  margin: 0 0.25rem;
  padding: 0.5rem 0.75rem;
  border: none;
  background-color: #f70081;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
}

.page-button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.page-button.active {
  background-color: #f31986;
  font-weight: bold;
}

.page-ellipsis {
  cursor: default;
  background-color: transparent;
  color: #000;
}

@media (max-width: 576px) {
  .icon-class-bg {
    width: 24px;
    height: 24px;
  }
}


@media (max-width: 768px) {

  .page-button,
  .page-ellipsis {
    padding: 0.4rem 0.6rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {

  .page-button,
  .page-ellipsis {
    padding: 0.3rem 0.5rem;
    font-size: 0.8rem;
  }
}

@media (max-width: 400px) {

  .page-button,
  .page-ellipsis {
    padding: 0.2rem 0.4rem;
    font-size: 0.7rem;
  }
}

.page-button:hover:not(:disabled),
.page-button:focus:not(:disabled) {
  background-color: #e00074;
  outline: none;
}
</style>
