<template>
  <div class="row justify-content-center">
    <div class="col">
      <div class="text-center" v-if="isloading">
        <loadersoda />
      </div>
      <div v-else class="modal fade" id="subcriptionModal" tabindex="-1" aria-labelledby="subcriptionModalLabel"
        aria-hidden="true">
        <!-- Add modal-dialog-scrollable to enable scrolling within the modal body -->
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg custom-modal-width">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="subcriptionModalLabel">{{ main_Title }}</h5>
              <button type="button" class="btn-close" id="closeSubscriptionModal" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>

            <div v-if="modal_Sub_Error" class="alert alert-danger mx-4 br-radius text-center p-1">
              {{ message }}
            </div>

            <!-- The modal-body will now scroll if content is too long -->
            <div class="modal-body text-center" v-html="content"></div>

            <div class="generate-Ai"></div>
            <div class="row p-2">
              <div class="col-6 col-md-5 pe-0 col-lg-5 align-content-center">
                <div class="form-check" v-if="isActive">
                  <input class="form-check-input" @click="removeSubModal(popupID)" type="checkbox" value=""
                    id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                    Don't Show Again
                  </label>
                </div>
              </div>
              <div class="col-6 col-md-7 col-lg-7 ps-2" v-if="Button_CTA && cta_Link">
                <a :href="cta_Link">
                  <button type="button" class="btn bg-button-h w-75">
                    {{ Button_CTA }}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { subscriptionModal, removeSubscriptionPopup } from '@/apis';
import globalErrorHandler from '@/globalErrorHandler';

export default {
  name: 'ShowModalSubscription',
  data() {
    return {
      isloading: false,
      modal_Sub_Error: false,
      message: "",
      content: "",
      main_Title: "",
      Button_CTA: "",
      cta_Link: "",
      isActive: false,
      popupID: "",
    }
  },
  methods: {
    async open() {
      // Fetch subscription data only when the modal is about to open
      this.modal_Sub_Error = false;
      this.isloading = true;
      try {
        const subscriptionPopup = await subscriptionModal();
        this.content = subscriptionPopup.body;
        this.main_Title = subscriptionPopup.title;
        this.Button_CTA = subscriptionPopup.cta_name;
        this.cta_Link = subscriptionPopup.cta_url;
        this.isActive = subscriptionPopup.dont_show_again_option;
        this.popupID = subscriptionPopup.id;
      } catch (err) {
        this.modal_Sub_Error = true;
        this.message = globalErrorHandler(err)[0];
      } finally {
        this.isloading = false;
      }
      // Wait for the DOM to update so that the modal element is actually present
      await this.$nextTick();

      const modalElement = document.getElementById('subcriptionModal');
      const modalInstance = new bootstrap.Modal(modalElement);
      modalInstance.show();
    },

    async removeSubModal(id) {
      this.modal_Sub_Error = false;
      try {
        const removeModal = await removeSubscriptionPopup(id);
        document.getElementById('closeSubscriptionModal').click();
        console.log(removeModal);
      } catch (err) {
        this.modal_Sub_Error = true;
        this.message = globalErrorHandler(err)[0];
      }
    }
  }
}
</script>
<style scoped>
.custom-modal-width {
  max-width: 70vw;
}

@media (max-width: 576px) {

  /* On extra small screens (phones), allow nearly full width */
  .custom-modal-width {
    max-width: 95vw;
    margin: auto;
  }
}

.modal-dialog-scrollable .modal-body {
  overflow-y: scroll;
  /* Force scroll so scrollbar is always visible */
  scrollbar-width: thin;
  /* For Firefox */
}

/* For WebKit-based browsers (Chrome, Safari, Edge) */
.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  width: 8px;
  /* Customize width */
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Track color */
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar-thumb {
  background-color: #999;
  /* Thumb color */
  border-radius: 4px;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar-thumb:hover {
  background-color: #888;
  /* Hover color */
}
</style>