<template>
  <div class="addon-card card w-100 flex-column flex-md-row align-items-center">
    <!-- Logo Column -->
    <div class="logo-column mb-3 mb-md-0 me-md-3 align-self-center align-self-md-start">
      <img :src="logo" class="icon-image" alt="Addon Icon" />
    </div>

    <!-- Content Column -->
    <div class="content-column flex-grow-1 text-center text-md-start">
      <h6 class="font-w600 font-bold mb-2">{{ title }}</h6>
      <p class="mb-1">Total: {{ total }}</p>
      <button class="btn btn-primary w-100 w-md-auto" @click="$emit('add')">
        Add {{ title }}
      </button>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";

export default {
  props: {
    title: String,
    used: Number,
    total: Number,
    logo: String
  },
  computed: {
    ...mapGetters(['GET_BASE_SUBSCRIPTION']),
    progressPercentage() {
      return this.total ? (this.used / this.total) * 100 : 0;
    }
  }
};
</script>

<style scoped>
.addon-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

@media (min-width: 768px) {
  .addon-card {
    flex-direction: row;
    align-items: center;
  }
}

@media (max-width: 767.98px) {
  .addon-card {
    text-align: center;
  }

  .btn-primary {
    width: 100%;
  }
}

.logo-column {
  flex-shrink: 0;
}

.icon-image {
  width: 40px;
  height: auto;
  /* object-fit: contain; */
}

@media (max-width: 767.98px) {
  .icon-image {
    width: 60px;
  }
}

@media (max-width: 991.98px) {

  /* Increase icon size on tablets and small laptops */
  .icon-image {
    width: 70px;
  }
}

.content-column {
  flex-grow: 1;
}

.btn-primary {
  border-color: #f31986;
  color: #ffffff;
  background-color: #ED3341;
}
</style>
