<template>
  <div class="container-fluid bgcustom pt-3 pb-0 mt-2">
    <div class="card shadow rounded-5 hborder mx-auto w-75" style="max-width: 1400px;">
      <div class="card-body text-center">
        <img src="/assets/images/logo-soda.svg" class="logo-width" alt="">
      </div>
    </div>
  </div>
  <div class="container-fluid bgcustom pt-3 pb-5 mt-0">
    <div class="card shadow rounded-5 hborder mx-auto w-75" style="max-width: 1400px;">
      <div class="card-body text-center">
        <div v-if="hasError" class="alert alert-danger container w-75 ">
          <span v-for="message in messages">{{ message }}</span>
        </div>
      </div>
      <div class="card-body text-center">
        <i class="fa fa-envelope custom-icon-color icon-w mb-3"></i>
        <h5 class="fw-lighter mb-3 h5 colorfontdark text-uppercase">Verify your email address</h5>
        <h6 class="text-center text-capitalize lh-base">Please check your inbox and enter the verification code below to
          verify your email address.</h6>
        <form action="" @submit.prevent="verifyEmail">
          <div class="row">
            <div class="col py-2">
              <input type="text" class="form-control" placeholder="Please Enter Code Here" name="code" v-model="code"
                autocomplete="off" />
            </div>
          </div>
          <div class="row">
            <div class="col pt-2 text-center">
              <button type="submit" class="btn btn-generate w-auto">VERIFY</button>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col pt-2 text-center button-h">
              <button type="button" class="btn btn-link p-color" @click="resendVerify()">Resend Code</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { verifyEmail, resendVerificationEmail, retrieveBaseSubscriptions } from '@/apis';
import globalErrorHandler from '@/globalErrorHandler';

export default {
  name: "EmailVerify",
  props: {
    islogedin: {
      type: [Boolean],
      default: false,
    },
  },
  data() {
    return {
      email: this.$route.params.email,
      hasError: false,
      messages: [],
      code: "",
      isLoading: false,
      loading: false,
    }
  },
  methods: {
    async verifyEmail() {
      this.isLoading = true;
      this.hasError = false;
      this.messages = [];

      try {
        // Verify email with code
        const res = await verifyEmail({
          email: this.email,
          code: this.code,
        });
        // Track the "Verify_email" event in GTM
        this.$gtm.trackEvent({
          event: "Verify_email",
          category: "User",
          action: "Email Verification",
          label: this.email, // Email from route params
          value: 1, // Optional: Indicates success
        });

        // On success, store user data in Vuex
        this.$store.commit('SET_USER_DATA', { token: res.token, user: res.user });

        // Retrieve base subscription
        const resb = await retrieveBaseSubscriptions();
        this.$store.commit('SET_BASE_SUBSCRIPTION', resb);

        // Redirect to the home page
        window.location.href = '/';
      } catch (err) {
        // Handle verification errors
        let error = err.response?.data || err.message;

        // If `error_num` is 303, redirect to email verification
        if (err.response?.data?.error_num === 303) {
          return this.$router.push({ path: "/emailverify/" + this.email });
        }

        // Extract error message using globalErrorHandler
        this.messages = globalErrorHandler(err.data)[0];
        this.hasError = true;
        window.scroll(0, 0);
      } finally {
        this.isLoading = false;
      }
    },

    async resendVerify() {
      this.loading = true;
      this.hasError = false;
      this.messages = [];

      try {
        // Attempt to resend verification email
        await resendVerificationEmail({ email: this.email });
        // Track the "Resend Code" event in GTM
        this.$gtm.trackEvent({
          event: "Resend_Code",
          category: "User",
          action: "Resend Verification Email",
          label: this.email,
          value: 1,
        });
        this.messages = ["The code has been sent successfully"];
        this.hasError = true;
      } catch (err) {
        // Handle resend errors
        this.messages = globalErrorHandler(err.response?.data?.errors || err.message);
        this.hasError = true;
        window.scroll(0, 0);
      } finally {
        this.loading = false;
      }
    }
  }

}
</script>

<style scoped>
.bgcustom {
  background-color: #f5f5f5;
}

.card {
  background-color: #ffffff;
  border-radius: 17px;
}

.icon-w {
  font-size: 30px !important;
}
</style>