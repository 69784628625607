<template>
  <div class="container-fluid">
    <NavBar v-if="showNavBar" />
    <div class="row justify-content-center">
      <div class="col-12 col-sm-2 col-xxl-1 col_Hidden" v-if="showMenuBar">
        <MenuBar />
      </div>
      <div :class="contentColumnClass" v-if="!DATA_LOADING && GET_ROUTE">
        <loadersoda v-if="$store.state.globalLoading" class="global-loader" />
        <router-view />
        <!-- Register PlansModal with a ref for global access -->
        <PlansModal ref="plansModal" />
        <!-- Subscription Show Modal with a ref for global access -->
        <ShowModalSubscription ref="showModalSub" />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import MenuBar from '@/components/MenuBar.vue';
import loadersoda from '@/components/LoaderSoda.vue';
import PlansModal from "@/views/Billing/New/PlansModal.vue";
import ShowModalSubscription from "@/views/Billing/New/ShowModalSubscription.vue";
import { mapGetters } from 'vuex';
// import globalErrorHandler from './globalErrorHandler'

export default {
  data() {
    return {
      loader: true,
      message: "",
      isLoading: false,
      access_Role: localStorage.getItem("role"),
    }
  },
  components: {
    NavBar,
    MenuBar,
    loadersoda,
    PlansModal,
    ShowModalSubscription,

  },
  computed: {
    ...mapGetters(['GET_USER', 'DATA_LOADING', 'GET_ROUTE', 'GET_BASE_SUBSCRIPTION']),
    currentRouteName() {
      return this.$router.currentRoute.value.name;
    },
    showNavBar() {
      return (
        this.GET_USER &&
        !['free-trial-expired', 'NotFound', 'Unpaid_Credit', 'Checkout'].includes(this.currentRouteName) && !['invitation'].includes(this.currentRouteName)
      );
    },
    showMenuBar() {
      return (
        this.GET_USER &&
        !['editor', 'cdesign', 'updesign', 'free-trial-expired', 'NotFound', 'invitation', 'Unpaid_Credit', 'Checkout', 'SVGEditor'].includes(this.currentRouteName)
      );
    },
    contentColumnClass() {
      return !['editor', 'cdesign', 'updesign', 'login', 'free-trial-expired', 'NotFound', 'register', 'ForgetPassword', 'SVGEditor'].includes(this.currentRouteName)
        ? 'col-12 col-sm-10 col-xxl-11'
        : 'col-12 col-sm-12 col-xxl-12';
    },
  },

  methods: {
    openPlansModal() {
      this.$refs.plansModal.open();
    },

    openSubcriptionModal() {
      setTimeout(()=> {
        let showPopup = this.GET_BASE_SUBSCRIPTION;
        if(showPopup && showPopup.show_popup){
          this.$refs.showModalSub.open();
        }
      },500)
    },
  },
  created() {
    const token = localStorage.getItem('token');
    this.openSubcriptionModal();
  }
};
</script>
<style>
.global-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-fluid {
  background-color: #f5f5f5;
}

.bgcustom {
  background-color: #f5f5f5 !important;
}

.design-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
}


.plan-card {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.modal-content {
  border-radius: 10px;
  overflow: hidden;
}

.modal-header {
  border-bottom: none;
}

.choose-plan-btn {
  background-color: #F21A61;
  color: white;
}

.choose-plan-btn:hover {
  background-color: #ED3341;
}
</style>
