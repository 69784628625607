<template>
  <!-- Placeholder when items are empty -->
  <div v-if="items.length === 0" class="col-12 text-center">
    <div class="placeholder-card border rounded p-3">
      <p>No saved cards available.</p>
      <p>Please add a new card.</p>
    </div>
  </div>
  <div v-else class="col-sm-6" v-for="item in items" :key="item.id">
    <div class="modern-card border rounded p-3">
      <div v-if="errors" class="alert alert-danger mx-4 text-center p-1 br-radius">
        <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
      </div>
      <!-- Header with Primary Badge -->
      <h6 class="mb-3">
        {{ item.name }}
        <span v-if="item.is_primary" class="badge-primary">Primary</span>
      </h6>

      <!-- Card Details -->
      <div class="d-flex align-items-center mb-3" v-if="type === 'card'">
        <div class="card-logo-wrapper me-3">
          <img :src="item.logo" alt="Card Logo" class="card-logo" />
        </div>
        <div>
          <h6 class="mb-1 text-muted">{{ item.type }} **** {{ item.last4 }}</h6>
          <p class="expiry-text">Expires at {{ item.expiry }}</p>
        </div>
      </div>

      <!-- Subscription Details -->
      <div v-else-if="type === 'subscription'" class="subscription-details">
        <p><strong>Subscription:</strong> {{ item.subscription_name }}</p>
        <p><strong>Valid till:</strong> {{ item.valid_till }}</p>
        <p><strong>Details:</strong> {{ item.details }}</p>
      </div>

      <!-- Action Buttons -->
      <div class="action-buttons">
        <button v-if="type === 'card' && !item.is_primary" class="btn-set-primary" @click="setPrimary(item.id)">
          Set Primary
        </button>
        <button v-if="type === 'card' && !item.is_primary" class="btn-delete" @click="deleteCard(item.id)">
          Delete
        </button>
      </div>
    </div>
    <NotificationPopup v-if="showNotification" :message="notificationMessage" :icon-src="notificationIcon"
      :duration="3000" @close="showNotification = false" />
  </div>
</template>

<script>
import NotificationPopup from "@/views/Billing/New/NotificationPopup.vue";
import globalErrorHandler from "@/globalErrorHandler";
import { setDefaultPaymentMethod, removePaymentMethod, retrieveSubscriptionByID } from "@/apis";

export default {
  name: "SavedCard",
  props: {
    items: Array,
    type: {
      type: String,
      required: true,
      validator: value => ["card", "subscription"].includes(value)
    }
  },
  emits: ['refreshCards'], // Declare the custom event here

  components: {
    NotificationPopup,
  },
  data() {
    return {
      errors: null,
      showNotification: false,
      notificationMessage: "",
      notificationIcon: "", // Add an appropriate icon path for each notification type
    };
  },
  methods: {
    async setPrimary(cardId) {
      try {
        await setDefaultPaymentMethod(cardId, { is_default: true });
        // Track GTM event for Default Payment Method Change
        this.$gtm.trackEvent({
          event: "Payment Method",
          category: "Billing",
          action: "Default Payment Method Changed",
          previous_method: previousDefault?.name || "None",
          new_method: newDefault?.name || "None",
          subscription_id: this.subscriptionId,
        });

        this.$emit("refreshCards");
        this.showNotificationMessage("Primary card updated successfully.", "/assets/images/checked.png");
      } catch (error) {
        this.errors = globalErrorHandler(error.data);
      }
    },
    async deleteCard(cardId) {
      try {
        await removePaymentMethod(cardId);
        this.$emit("refreshCards");
        this.showNotificationMessage("Card deleted successfully.", "/assets/images/checked.png");
      }
      catch (error) {
        this.errors = globalErrorHandler(error.data);
      }
    },
    showNotificationMessage(message, iconSrc) {
      this.notificationMessage = message;
      this.notificationIcon = iconSrc;
      this.showNotification = true;

      // Auto-hide the notification after 3 seconds
      setTimeout(() => {
        this.showNotification = false;
      }, 2000);
    },
  },
};

</script>

<style scoped>
/* Main card container with modern shadow and border */
.modern-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 1rem;
  min-height: 176px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.modern-card:hover {
  transform: translateY(-4px);
}

/* Primary badge styling */
.badge-primary {
  background-color: #4caf50;
  color: white;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  font-size: 0.75rem;
}

/* Card logo styling */
.card-logo-wrapper {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 0.5rem;
}

.card-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

/* Text and styling for expiry date */
.expiry-text {
  font-size: 0.85rem;
  color: #888888;
}

/* Subscription details styling */
.subscription-details p {
  margin: 0.3rem 0;
  font-size: 0.9rem;
}

/* Button styling for primary and delete actions */
.action-buttons {
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
}

.btn-set-primary {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-set-primary:hover {
  background-color: #0056b3;
}

.btn-delete {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-delete:hover {
  background-color: #b02a37;
}
</style>