<template>
  <div class="container-fluid bgcustom pt-3 pb-5 pe-4 ps-4">
    <div class="card shadow rounded-5 br-radius hborder pe-0 ps-1 py-3">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-8">
          <div class="row pb-2 mt-3">
            <div class="col-12 col-sm-6 brand-text">
              <div>USERS</div>
            </div>
            <div class="col-12 d-none d-sm-block col-sm-6 button-center text-end">
              <button class="btn bg-button-h space p-1 w-50" type="button" data-bs-toggle="modal"
                data-bs-target="#exampleModal">
                Invite User
              </button>
            </div>
            <div class="col-12 d-sm-none text-center col-sm-6 button-center">
              <button class="btn bg-button-h space p-1 w-50" type="button" data-bs-toggle="modal"
                data-bs-target="#exampleModal">
                Invite User
              </button>
            </div>
          </div>
          <div class="alert alert-danger br-radius p-1 text-center" v-if="errors.length > 0">
            <span v-for="error in errors">{{ error }}</span>
          </div>
          <div v-if="userLoading" class="row">
            <div class="col text-center">
              <loadersoda />
            </div>
          </div>
          <div v-else class="row">
            <div class="col">

              <div v-if="rows.length === 0" class="text-center">
                <div class="placeholder-card border rounded p-3">
                  <p>No Users Found.</p>
                </div>
              </div>

              <div v-else class="card bg-card ">
                <div class="card-body p-1">
                  <form action="">
                    <div class="row py-2" v-for="(row, index) in rows" :key="index">
                      <div class="col">
                        <div class="bg-white mx-2 p-2 br_radius">
                          <div class="row">
                            <div class="col-3 col-md-3 col-lg-3 ps-3">
                              <img src="/assets/images/Profile-image.png" class="logo-circle img-fluid width_img_user"
                                alt="">
                            </div>
                            <div class="col-9 col-md-5 col-lg-4 brand-text text-md-center text-lg-center p-0">
                              <span>{{ row.first_name }} {{ row.last_name }}</span><br>
                              <span class="font-12">{{ row.email }}</span>
                            </div>
                            <div class="col-8 col-md-5 col-lg-3 brand-text text-center">
                              <span>{{ row.access_role }}</span>
                            </div>
                            <div class="col-2 col-md-3 col-lg-2 text-center button-center font_Size_32">
                              <div class="dropdown d-flex" v-if="user_Role == 'owner' || user_Role == 'superadmin'">
                                <span class="color_Line mx-2">|</span>
                                <div class="dropdown-toggle broder_h d-flex color_Line pt-2" type="button"
                                  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                  <i class="fa-solid fa-ellipsis"></i>
                                </div>
                                <ul class="dropdown-menu card-border mouse" aria-labelledby="dropdownMenuButton1">
                                  <li class="dropdown-item" @click.prevent="update_User_Data('superadmin', row.id)">
                                    Change Role To Super Admin
                                  </li>
                                  <li class="dropdown-item" @click.prevent="update_User_Data('admin', row.id)">Change
                                    Role To Admin
                                  </li>
                                  <li class="dropdown-item" @click.prevent="update_User_Data('user', row.id)">Change
                                    Role To User
                                  </li>
                                  <li v-if="user_Role == 'owner'" class="dropdown-item"
                                    @click.prevent="delete_User(row.id, index)">Remove User
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--begin:modal-->
      <div class="row">
        <div class="col">
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Add user</h5>
                  <button type="button" class="btn-close" id="modal_User_Close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <!-- Error Message -->
                  <div class="alert alert-danger br-radius p-1 text-center" v-if="errors.length > 0">
                    <span v-for="error in errors">{{ error }}</span>
                  </div>
                  <div class="row pt-2">
                    <div class="col">
                      <input type="text" class="form-control" v-model="firstName" id="first" placeholder="First Name" />
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col">
                      <input type="text" class="form-control" v-model="lastName" id="last" placeholder="Last Name" />
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col">
                      <input type="email" class="form-control" v-model="email" id="filedEmail" placeholder="Email" />
                    </div>
                  </div>
                  <div class="row pt-2">
                    <div class="col">
                      <select class="form-select" v-model="role" aria-label="Select the role">
                        <option value="" disabled>Select the role</option>
                        <option value="user">User</option>
                        <option value="superadmin">Super Admin</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <loader v-if="isloading" />
                  <button v-else type="button" class="btn bg-button" @click.prevent="validateAndAddUser">
                    Send Invitation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end modal-->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { InviteUser, listUsers, updateUser, deleteUser } from '@/apis.js';
import globalErrorHandler from "@/globalErrorHandler";

export default {
  name: 'users',
  data() {
    return {
      userLoading: false,
      errors: [],
      firstName: "",
      lastName: "",
      email: "",
      role: "",
      isloading: false,
      rows: [],
      user_Role: "",
    }
  },
  computed: {
    ...mapGetters(['BASE_URL']),
  },
  methods: {
    validateAndAddUser() {
      // Clear previous errors
      this.errors = [];

      // Validate fields
      if (!this.firstName) this.errors.push("First name is required.");
      if (!this.lastName) this.errors.push("Last name is required.");
      if (!this.email) this.errors.push("Email is required.");
      if (!this.role) this.errors.push("Role is required.");

      // If errors exist, do not proceed
      if (this.errors.length > 0) {
        return;
      }

      // Proceed to add user
      this.add_User();
    },
    getData_User_Auth() {
      this.userLoading = true;
      this.$http.get("/api/auth/user/").then(res => {
        this.userLoading = false;
        this.user_Role = res.data.data.access_role
        localStorage.setItem('access_role', this.user_Role);
      });
    },
    async getData_User() {
      this.userLoading = true;
      try {
        const data = await listUsers();
        this.rows = data.results;
        this.userLoading = false;
      } catch (err) {
        this.errors = globalErrorHandler(err);
        this.userLoading = false;
      }
    },
    async add_User() {
      this.isloading = true;
      try {
        await InviteUser({
          email: this.email,
          access_role: this.role,
          first_name: this.firstName,
          last_name: this.lastName,
        });
        this.isloading = false;
        document.getElementById('modal_User_Close').click();

        // Clear input fields
        this.clearInputs();

        // Refresh user list
        await this.getData_User();
      } catch (err) {
        this.errors = globalErrorHandler(err);
        this.isloading = false;

        // Clear input fields on error
        this.clearInputs();
      }
    },
    clearInputs() {
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.role = "";
    },
    resetModal() {
      this.errors = []; // Clear error messages
      this.clearInputs(); // Clear input fields
    },

    async update_User_Data(change, user_id) {
      try {
        await updateUser(user_id, { access_role: change });
        await this.getData_User();
      } catch (err) {
        this.errors = globalErrorHandler(err);
        this.isloading = false;
        window.scroll(0, 0);

      }
    },
    async delete_User(id_User, index) {
      swal({
        title: "Are you sure?",
        text: "You will not be able to recover this User!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, delete it!",
        closeOnConfirm: true
      },
        async () => await this.remove_User(id_User, index)
      );
    },
    async remove_User(id_User, index) {
      try {
        await deleteUser(id_User);
        await this.getData_User();
      } catch (err) {
        this.errors = globalErrorHandler(err);
        window.scroll(0, 0);

      }
    },
  },
  mounted() {
    // Attach event listener to reset the modal when it is closed
    const modal = document.getElementById('exampleModal');
    modal.addEventListener('hide.bs.modal', this.resetModal);
  },

  created() {
    this.getData_User();
    this.getData_User_Auth();
  }
}
</script>