<template>
  <transition name="slide-up-down" @before-leave="addExitAnimation" @leave="removeModal">
    <div v-if="isVisible" class="modal-overlay" @click.self="close">
      <div class="modal-content full-screen-modal card shadow rounded-5 animate-slide-up min-vw-100">
        <div class="card-body">
          <!-- Header and Close Button -->
          <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="flex-grow-1 text-center">
              <h2 class="fw-bold mb-0">Choose the Best Plan for Your Business</h2>
            </div>
            <button type="button" class="btn-close ms-3" @click="close"></button>
          </div>
          <div v-if="errors" class="alert alert-danger mx-4 text-center p-1 br-radius">
            <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
          </div>
          <!-- Success Popup Modal -->
          <div v-if="showSuccessPopup" class="modal fade show d-block" tabindex="-1" aria-hidden="true"
            style="background: rgba(0, 0, 0, 0.5);">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content text-center p-4">
                <img src="/assets/images/checked.png" alt="Success" class="mb-3 align-self-center"
                  style="width: 50px; height: 50px;" />
                <h5 class="text-success">Success!</h5>
                <p>Your plan has been updated successfully.</p>
              </div>
            </div>
          </div>
          <!-- Upgrade Confirmation Popup -->
          <div v-if="showUpgradeConfirmation" class="modal-overlay centered-modal"
            @click.self="closeUpgradeConfirmation">
            <div class="modal-content card shadow rounded-5 animate-fade-in">
              <div class="card-body text-center">
                <!-- Header and Close Button -->
                <div class="d-flex justify-content-between align-items-center mb-4">
                  <div class="flex-grow-1 text-center">
                    <h2 class="fw-bold mb-0">Confirm Upgrade</h2>
                  </div>
                  <button type="button" class="btn-close ms-3" @click="closeUpgradeConfirmation"></button>
                </div>
                <!-- Display Current Plan -->
                <p><strong>Current Plan:</strong> {{ this.GET_BASE_SUBSCRIPTION?.display_name }}</p>
                <!-- Display Selected Plan -->
                <p><strong>Selected Plan:</strong> {{ selectedPlanForConfirmation.name }}</p>
                <!-- Display Default Payment Method -->
                <div>
                  <h5 class="text-center">Default Payment Method</h5>
                  <div v-if="defaultPaymentMethod" class="my-3">
                    <div class="saved-card-container d-flex flex-column align-items-center">
                      <SavedCard :items="[defaultPaymentMethod]" type="card" inline />
                      <button class="btn btn-link add-promo-code-btn mt-3" @click="goToBilling">Change Payment
                        Method</button>
                    </div>
                  </div>
                  <div v-else class="text-center">
                    <p>No default payment method found.</p>
                    <button class="btn btn-primary" @click="goToBilling">Add Payment Method</button>
                  </div>
                </div>
                <!-- Explanation Text -->
                <p>You are about to upgrade to the {{ selectedPlanForConfirmation.name }} plan.</p>
                <div v-if="priceDifference">
                  <p>
                    The additional amount to be paid is
                    <strong>${{ (priceDifference.amount / 100).toFixed(2) }}</strong>.
                  </p>
                </div>

                <!-- Pay Now Button -->
                <button class="btn btn-primary w-75" @click="confirmUpgrade">Pay Now</button>
              </div>
            </div>
          </div>

          <!-- Downgrade Confirmation Popup -->
          <div v-if="showDowngradeConfirmation" class="modal-overlay centered-modal"
            @click.self="closeDowngradeConfirmation">
            <div class="modal-content card shadow rounded-5 animate-fade-in">
              <div class="card-body text-center">
                <!-- Header and Close Button -->
                <div class="d-flex justify-content-between align-items-center mb-4">
                  <div class="flex-grow-1 text-center">
                    <h2 class="fw-bold text-center">Confirm Downgrade</h2>
                  </div>
                  <button type="button" class="btn-close" @click="closeDowngradeConfirmation"></button>
                </div>
                <!-- Display Current Plan -->
                <p><strong>Current Plan:</strong> {{ this.GET_BASE_SUBSCRIPTION?.display_name }}</p>
                <!-- Display Selected Plan -->
                <p><strong>Selected Plan:</strong> {{ selectedPlanForConfirmation.name }}</p>
                <!-- Notification Text -->
                <p>Your plan will be downgraded on the next billing cycle.</p>
                <!-- Downgrade Now Button -->
                <button class="btn btn-primary" @click="confirmDowngrade">Downgrade Now</button>
              </div>
            </div>
          </div>

          <div v-if="access_Role === 'owner'">
            <div class="d-flex align-items-center justify-content-center my-3 ms-5">
              <!-- Button Group for Monthly/Yearly -->
              <div class="btn-group rounded-pill" role="group" aria-label="Billing Cycle">
                <button type="button" class="btn btn-outline-primary rounded-pill1"
                  :class="{ active: billingCycle === 'monthly' }" @click="billingCycle = 'monthly'">
                  Monthly
                </button>
                <button type="button" class="btn btn-outline-primary rounded-pill2"
                  :class="{ active: billingCycle === 'yearly' }" @click="billingCycle = 'yearly'">
                  Yearly
                </button>
              </div>
              <img src="/assets/images/offersave.svg" alt="Save 10%" class="save-image ms-0" />
            </div>
            <div class="row justify-content-center ">
              <div class="col-md-4 mb-4" v-for="(plan, index) in rows" :key="index">
                <div :class="['plan-card', { 'selected': isActive(getPricingId(plan.pricing)) }]"
                  @mouseover="hoveredIndex = index" @mouseleave="hoveredIndex = null">

                  <div class="card-body d-flex flex-column align-items-baseline">
                    <h3 class="card-title fw-bold">{{ plan.name }}</h3>
                    <p class="text-start fw-bold">
                      <span v-if="billingCycle === 'yearly'">
                        <h5 class="text-decoration-line-through text-muted">{{ getOriginalMonthlyPrice(plan) }}
                        </h5>
                        <h3 class="mb-1">{{ getDiscountedMonthlyPrice(plan) }}</h3>
                        <small class="text-muted">Billed Annually</small> <!-- Billed Annually Text -->
                      </span>
                      <span v-else>
                        <h3 class="mb-1">{{ getMonthlyPrice(plan) }}</h3> <!-- Monthly Price -->
                      </span>
                    </p>
                    <!-- Plan Features -->
                    <ul class="list-unstyled text-start my-4 plan-features lh-lg">
                      <li v-for="(feature, featIndex) in plan.features" :key="featIndex">
                        <i class="fa-solid fa-check iconcolor"></i>{{ feature.name }}
                      </li>
                    </ul>
                    <button class="btn btn-primary mt-auto w-100 choose-plan-btn" @click="selectPlan(plan)">
                      <span v-if="isDowngrade(plan) && !isActive(getPricingId(plan.pricing))">Downgrade To {{ plan.name
                        }}</span>
                      <span v-else-if="isUpgrade(plan) && !isActive(getPricingId(plan.pricing))">Upgrade To {{ plan.name
                        }}</span>
                      <span v-else>Your Current Plan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Non-owner message -->
          <div v-else>
            <p class="text-center alert alert-warning">Please contact the account owner for an upgrade.</p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  upgradeSubscription,
  downgradeSubscription,
  fetchPlans,
  retrieveBaseSubscriptions,
  listPaymentMethods,
  getPriceDifference,
} from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";
import { mapGetters } from "vuex";
import SavedCard from "@/views/Billing/New/SavedCard.vue"; // Import SavedCard component

export default {
  data() {
    return {
      rows: [],
      errors: null,
      plan_id: "",
      billingCycle: "yearly",
      hoveredIndex: null,
      planError: false,
      message: "",
      objPlan: {},
      subscribeError: false,
      access_Role: localStorage.getItem("role"),
      isVisible: false,
      showSuccessPopup: false,
      // New data properties
      showUpgradeConfirmation: false,
      showDowngradeConfirmation: false,
      selectedPlanForConfirmation: null,
      defaultPaymentMethod: null,
      priceDifference: null,
    };
  },
  computed: {
    ...mapGetters(['GET_BASE_SUBSCRIPTION']),

    isFreePlan() {
      return this.GET_BASE_SUBSCRIPTION?.plan === 'free';
    }
  },
  components: {
    SavedCard, // Register SavedCard component
  },
  methods: {
    open() {
      this.errors = null;
      this.planProduct();
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
      this.$emit("close");
    },
    addExitAnimation(el) {
      setTimeout(() => {
        this.$emit("close"); // Emit close event after animation finishes
      }, 400);
    },

    // Fetch available plans and current user's plan
    async planProduct() {
      try {
        const [plansRes, subRes] = await Promise.all([fetchPlans(), retrieveBaseSubscriptions()]);

        // Set available plans and the user's current plan
        this.rows = plansRes.results.slice(1);
        this.objPlan = {
          subscription_id: subRes.id,
          pricing_id: subRes.pricing.id,
          plan_id: subRes.plan.id,
          plan_name: subRes.plan.name,
          amount: subRes.pricing.amount,
        };
      } catch (err) {
        this.planError = true;
        this.message = globalErrorHandler(err.data)[0];
      }
    },
    // Get pricing ID based on the selected billing cycle
    getPricingId(pricingArray) {
      if (!Array.isArray(pricingArray)) {
        return null;
      }
      const interval = this.billingCycle === "yearly" ? "year" : "month";
      const pricing = pricingArray.find((p) => p.interval === interval);
      return pricing ? pricing.id : null;
    },

    // Check if a plan is currently active
    isActive(pricingId) {
      return this.objPlan.pricing_id === pricingId;
    },

    // Determine if the selected plan is an upgrade
    isUpgrade(plan) {
      if (!plan || !Array.isArray(plan.pricing)) return false;
      const selectedPricing = plan.pricing.find((p) => p.interval === (this.billingCycle === "yearly" ? "year" : "month"));
      return selectedPricing && selectedPricing.amount > this.objPlan.amount;
    },

    // Determine if the selected plan is a downgrade
    isDowngrade(plan) {
      if (!plan || !Array.isArray(plan.pricing)) return false;
      const selectedPricing = plan.pricing.find((p) => p.interval === (this.billingCycle === "yearly" ? "year" : "month"));
      return selectedPricing && selectedPricing.amount < this.objPlan.amount;
    },

    // Handle plan selection and initiate upgrade/downgrade process
    async selectPlan(plan) {
      if (!plan || !Array.isArray(plan.pricing)) {
        return;
      }
      const selectedPricing = plan.pricing.find(
        (p) => p.interval === (this.billingCycle === "yearly" ? "year" : "month")
      );
      if (!selectedPricing) {
        return;
      }
      if (selectedPricing.id === this.objPlan.pricing_id) return; // Prevent selecting the current plan again

      // Track GTM event for Plan Selection
      this.$gtm.trackEvent({
        event: "Plan Selection",
        category: "Plans",
        action: "Select Plan",
        label: plan.name,
        value: parseFloat(selectedPricing.amount / 100), // Plan price
        billing_cycle: this.billingCycle,
      });


      // Check if current plan is 'free'
      if (this.GET_BASE_SUBSCRIPTION?.plan === 'free') {
        this.isVisible = false;
        this.$emit("close");
        this.$router.push({ path: `/checkout/${selectedPricing.id}` });
        return; // Exit function to prevent further execution
      }

      // For upgrade/downgrade, show confirmation popup
      this.selectedPlanForConfirmation = plan;

      try {
        // Fetch price difference
        const pricingId = selectedPricing.id;
        this.priceDifference = await getPriceDifference(pricingId);
      } catch (error) {
        this.errors = globalErrorHandler(error);

        return; // Exit the function if necessary
      }


      if (this.isUpgrade(plan)) {
        // Fetch default payment method
        await this.fetchDefaultPaymentMethod();
        this.showUpgradeConfirmation = true;
      } else if (this.isDowngrade(plan)) {
        this.showDowngradeConfirmation = true;
      }
    },

    // Fetch default payment method
    async fetchDefaultPaymentMethod() {
      try {
        const response = await listPaymentMethods();
        // Map the results to suit the SavedCard component structure
        const cards = response.results.map(card => ({
          id: card.id,
          name: card.type_object.display_brand.charAt(0).toUpperCase() + card.type_object.display_brand.slice(1),
          last4: card.type_object.last4,
          type: card.type_object.brand,
          is_primary: card.is_default,
          expiry: `${card.type_object.exp_month.toString().padStart(2, '0')}/${card.type_object.exp_year}`, // Format MM/YYYY
          logo: `/assets/images/${card.type_object.brand.toLowerCase()}.jpg`
        }));
        // Filter to get only the default payment method
        this.defaultPaymentMethod = cards.find(card => card.is_primary);
      } catch (error) {
        this.errors = globalErrorHandler(error.data);
        window.scroll(0, 0);
      }
    },

    // Close Upgrade Confirmation
    closeUpgradeConfirmation() {
      this.showUpgradeConfirmation = false;
    },

    // Close Downgrade Confirmation
    closeDowngradeConfirmation() {
      this.showDowngradeConfirmation = false;
    },

    // Navigate to Billing Page
    goToBilling() {
      setTimeout(() => {
        this.$router.push({ path: '/billing' }).then(() => {
          window.location.reload();
        });
      }, 500);
    },

    // Confirm Upgrade Action
    async confirmUpgrade() {
      try {
        const selectedPricing = this.selectedPlanForConfirmation.pricing.find((p) => p.interval === (this.billingCycle === "yearly" ? "year" : "month"));
        await upgradeSubscription(this.objPlan.subscription_id, { pricing: selectedPricing.id });
        this.showUpgradeConfirmation = false;

        // Track GTM event for Upgrade
        this.$gtm.trackEvent({
          event: "Purchase",
          category: "Upgrade",
          action: "Subscription Upgrade",
          label: selectedPricing.name || "Unknown Plan",
          value: parseFloat((selectedPricing.amount / 100).toFixed(2)), // Payment amount
          currency: "USD",
          plan: selectedPricing.name,
          pricing_id: selectedPricing.id,
        });

        // Show success popup
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
          this.$router.push({ path: '/billing' }).then(() => {
            window.location.reload();
          });
        }, 2800);
      } catch (err) {
        this.showUpgradeConfirmation = false;
        const errs = globalErrorHandler(err.data);
        this.errors = errs;
      }
    },

    // Confirm Downgrade Action
    async confirmDowngrade() {
      try {
        const selectedPricing = this.selectedPlanForConfirmation.pricing.find((p) => p.interval === (this.billingCycle === "yearly" ? "year" : "month"));
        await downgradeSubscription(this.objPlan.subscription_id, { pricing: selectedPricing.id });
        this.showDowngradeConfirmation = false;

        // Track GTM event for Downgrade Confirmation
        this.$gtm.trackEvent({
          event: "Purchase",
          category: "Downgrade",
          action: "Subscription Downgrade",
          label: selectedPricing.name || "Unknown Plan",
          value: parseFloat((selectedPricing.amount / 100).toFixed(2)), // Plan price
          currency: "USD",
          plan: selectedPricing.name,
          pricing_id: selectedPricing.id,
        });

        // Show success popup
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
          this.$router.push({ path: '/billing' }).then(() => {
            window.location.reload();
          });
        }, 2800);
      } catch (err) {
        this.showDowngradeConfirmation = false;
        const errs = globalErrorHandler(err.data);
        this.errors = errs;
      }
    },

    // Format monthly price for display
    getMonthlyPrice(plan) {
      const pricing = plan.pricing?.[0];
      return pricing ? `$${(pricing.amount / 100).toFixed(2)}/Mo` : "N/A";
    },

    // Format discounted monthly price based on yearly pricing
    getDiscountedMonthlyPrice(plan) {
      const pricing = plan.pricing?.find((p) => p.interval === "year");
      return pricing ? `$${((pricing.amount / 100) / 12).toFixed(2)}/Mo` : "N/A";
    },

    // Get the original monthly price for comparison
    getOriginalMonthlyPrice(plan) {
      const pricing = plan.pricing?.[0];
      return pricing ? `$${(pricing.amount / 100).toFixed(2)}/Mo` : "N/A";
    },
  },
  created() {
    if (this.access_Role === "owner") {
      this.planProduct();
    }
  },
};
</script>

<style scoped>
/* Full-Screen Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 9999;
}

.full-screen-modal {
  width: 100%;
  height: 100vh;
  max-width: none;
  margin: 0;
  border-radius: 0;
  overflow-y: auto;
}

/* Slide-Up and Slide-Down Animations */
.animate-slide-up {
  animation: slide-up 0.4s ease-out;
}

.slide-up-down-leave-active {
  animation: slide-down 0.4s ease-in forwards;
}

/* Keyframes for Slide Animations */
@keyframes slide-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-down {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

/* .modal-content {
  max-width: 800px;
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  padding: 1.5rem;
} */

.bgcustom {
  background-color: #f5f5f5;
}

.card {
  background-color: #ffffff;
  border-radius: 17px;
}

.plan-card {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
}

.active {
  background-color: #ED3341;
  color: white;
}

.divider {
  border-top: 2px solid #949494FF;
  width: 50%;
}

.text-danger {
  color: #ED3341;
}

.logo-width {
  width: 150px;
}

@media (min-width: 766px) {
  .lead-padding {
    padding-right: 100px;
    padding-left: 100px
  }
}

.img-above-yearly {
  height: 80px;
}


/* Active state styling for buttons */
.btn-group .btn.active {
  background-color: #ED3341;
  color: white;
  border-color: #F21A61;
}

/* Custom rounded buttons */
.btn-group .btn {
  padding: 0.5rem 1.5rem;
  border-width: 2px;
  border-color: #F21A61;
  color: #333333;
}

.btn-group .btn:hover {
  background-color: #ED3341;
  color: #FFFFFFFF;
}

/* Styling for the "Save 10%" label */
.save-label {
  font-size: 14px;
  color: #ED3341;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
}

.rounded-pill1 {
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}

.rounded-pill2 {
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}

/* Custom image styling */
.save-image {
  width: 70px;
  /* Custom width for the image */
  height: auto;
  /* Maintain aspect ratio */
  vertical-align: middle;
  margin-top: -40px;
}

/* Plan Card Styling */
.plan-card {
  border-radius: 10px;
  padding: 1.5rem;
  background-color: white;
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: default;
  border: 1px solid #ddd;
  min-height: 460px;
}

.plan-card.hovered {
  background-color: #FFF4F6FF;
}

.plan-card.selected {
  border-color: #e26600;
  border-width: 3px;

}

.plan-card:hover {
  transform: translateY(-5px);
}

.choose-plan-btn {
  cursor: pointer;
  background-color: #F21A61;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.choose-plan-btn:hover {
  background-color: #ED3341;
  transform: translateY(-3px);
}

.plan-features {
  margin-top: 20px !important;
}

.iconcolor {
  color: #F21A61;
  margin-right: 8px;
}

.pricing-section {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 134px;
  font-family: Poppins, sans-serif;
}

.pricing-container {
  display: flex;
  width: 100%;
  max-width: 646px;
  flex-direction: column;
  align-items: center;
}

.pricing-title {
  color: #0b2110;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.32px;
  text-align: center;
}

.highlight {
  color: #349c4b;
}

.pricing-toggle {
  position: relative;
  display: flex;
  margin-top: 48px;
  flex-direction: column;
  font-weight: 600;
}

.toggle-container {
  border-radius: 27px;
  background-color: #fff;
  box-shadow: 0 10px 15px rgba(24, 59, 86, 0.2);
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  padding: 8px;
}

.toggle-button {
  border-radius: 16px;
  color: #626f65;
  padding: 4px 16px;
  background: none;
  border: none;
  cursor: pointer;
}

.toggle-button.active {
  background-color: #349c4b;
  color: #fff;
}

.savings-info {
  position: absolute;
  z-index: 0;
  display: flex;
  width: 156px;
  flex-direction: column;
  font-size: 12px;
  color: #7732bb;
  text-align: right;
  right: -135px;
  bottom: -21px;
}

.savings-icon {
  aspect-ratio: 2.58;
  object-fit: contain;
  width: 62px;
  margin-top: 6px;
}

.plans-container {
  margin-top: 88px;
  width: 100%;
  max-width: 1170px;
}

.plans-grid {
  display: flex;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .pricing-section {
    padding: 0 20px;
  }

  .pricing-title,
  .pricing-container,
  .plans-container {
    max-width: 100%;
  }

  .pricing-toggle {
    margin-top: 40px;
  }

  .plans-container {
    margin-top: 40px;
  }
}

/* Centered Modal Overlay */
.centered-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Fade-in Animation */
.animate-fade-in {
  animation: fade-in 0.4s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Rest of your styles... */

/* Full-Screen Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.full-screen-modal {
  width: 100%;
  height: 100vh;
  max-width: none;
  margin: 0;
  border-radius: 0;
  overflow-y: auto;
}

/* Main Modal Content */
.modal-content {
  background-color: #fff;
  border-radius: 12px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

/* Buttons */
.btn-primary {
  background-color: #e26600;
  border-color: #e26600;
}

.btn-primary:hover {
  background-color: #f31986;
  border-color: #f31986;
}

/* Use your brand colors */
.iconcolor {
  color: #e26600;
}

.plan-card.selected {
  border-color: #f31986;
}

.choose-plan-btn {
  background-color: #e26600;
}

.choose-plan-btn:hover {
  background-color: #f31986;
}


.saved-card-inline {
  display: flex;
  align-items: center;
}

.change-button {
  font-weight: bold;
  color: #ffffff;
  background-color: #e26600;
  border-color: #e26600;
}

.change-button:hover {
  background-color: #f31986;
  border-color: #f31986;
}

.add-promo-code-btn {
  color: #007bff;
  text-decoration: underline;
  padding: 0;
  border: none;
  background: none;
  font-weight: bold;
}
</style>
