<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal-content">
      <button class="close-btn" @click="$emit('close')">×</button>
      <div v-if="errors" class="alert alert-danger mx-4 text-center p-1 br-radius">
        <span v-for="(error, index) in errors" :key="index" class="text-color">{{ error }}</span>
      </div>
      <h5 class="modal-title">Add {{ capitalizeType }}</h5>

      <div class="modal-body">
        <div class="quantity-control">
          <button class="btn btn-primary" @click="decrement">-</button>
          <div class="input-container">
            <input type="text" v-model="quantity" class="quantity-input"
              :style="{ paddingRight: isStorageType ? '1.5rem' : '0' }" readonly />
            <span v-if="isStorageType" class="input-suffix">GB</span>
          </div>
          <button class="btn btn-primary" @click="increment">+</button>
        </div>
        <p>Total Cost: ${{ totalCost }}</p>
      </div>

      <div class="modal-footer align-items-center align-content-center justify-content-center">
        <button class="btn btn-primary w-75" @click="buyAddon">Pay</button>
      </div>
      <div class="m-auto pt-2">Your Saved Payment Method will be charged Immediately</div>
    </div>
    <!-- Success Modal -->
    <div v-if="showSuccessPopup" class="modal fade show d-block" tabindex="-1" aria-hidden="true"
      style="background: rgba(0, 0, 0, 0.5);">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content text-center p-4">
          <img src="/assets/images/checked.png" alt="Success" class="mb-3 align-self-center"
            style="width: 50px; height: 50px;" />
          <h5 class="text-success">Success!</h5>
          <p>Your {{ capitalizeType }} have been successfully added.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { retrieveProductByName, attachAddon } from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";


export default {
  props: {
    type: String
  },
  data() {
    return {
      quantity: 1,
      costPerUnit: 0,
      pricingId: null,
      isLoading: false,
      errors: null,
      showSuccessPopup: false

    };
  },
  computed: {
    totalCost() {
      return this.quantity * this.costPerUnit;
    },
    capitalizeType() {
      return this.type.charAt(0).toUpperCase() + this.type.slice(1);
    },
    isStorageType() {
      return this.type === "storage";
    }

  },
  watch: {
    type: {
      immediate: true,
      handler(newType) {
        this.retrieveCostPerUnit(newType); // Fetch cost whenever type changes
      }
    }
  },

  methods: {
    async retrieveCostPerUnit(type) {
      this.isLoading = true;
      try {
        const product = await retrieveProductByName(type);
        // Assuming the cost per unit is in `product.pricing.amount` and is in cents
        this.costPerUnit = product.pricing[0].amount / 100;
        this.pricingId = product.pricing[0].id;
      } catch (err) {
        this.isLoading = false;
        this.errors = globalErrorHandler(err.data);
        window.scroll(0, 0);
      } finally {
        this.isLoading = false;
      }
    },
    increment() {
      this.quantity++;
    },
    decrement() {
      if (this.quantity > 1) this.quantity--;
    },

    async buyAddon() {
      this.isLoading = true;
      try {
        this.isLoading = true;
        await attachAddon({
          pricing: this.pricingId,
          quantity: this.quantity,
        })
        this.showSuccessPopup = true;
        setTimeout(() => {
          this.showSuccessPopup = false;
          window.location.reload();
        }, 2000);

      }
      catch (err) {
        this.isLoading = false;
        this.errors = globalErrorHandler(err.data);
        window.scroll(0, 0);

      }
      // this.$emit('close');
    }
  }
};

</script>

<style scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  width: 500px;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: transparent;
  border: none;
  font-size: 1.5rem;
}

.quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 1rem 0;
}

.input-container {
  position: relative;
}

.quantity-input {
  width: 50px;
  text-align: center;
  padding-right: 1.5rem;
}

.input-suffix {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.9rem;
  color: #555;
}

.btn-primary {
  background-color: #E26600;
  border-color: #E26600;
}

.text-primary {
  color: #f31986;
}
</style>
