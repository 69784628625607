<template>
  <div class="login-page-main">
    <div class="container-fluid login-background">
      <div class="row col-12">
        <!-- Form Section -->

        <div class="col col-sm-7 d-flex justify-content-center align-items-center ">

          <div class="form-container ">
            <div class="text-center">
              <img src="/assets/images/logo-soda.svg" class="login-logo" alt="Company Logo" />
            </div>
            <div class="text-center mt-4">
              <h2 class="font-bold">Start Your Free 7 Days Trial</h2>
              <h4 class="text-muted">Instantly Generate All Your Graphic Designs & Social Media Content</h4>
            </div>
            <div v-if="registerError" class="alert alert-danger br-radius text-center mt-3">
              <span v-for="message in messages" :key="message">{{ message }}</span>
            </div>
            <form @submit.prevent="registerPost()" class="p-4">
              <div class="mb-3">
                <label class="font-color-g font-bold" for="firstName">First Name</label>
                <input type="text" id="firstName" class="form-control login-inputs" placeholder="Enter Your First Name"
                  v-model="firstName" required />
                <small v-if="firstNameError" class="text-danger">{{ firstNameError }}</small>
              </div>
              <div class="mb-3">
                <label class="font-color-g font-bold" for="lastName">Last Name</label>
                <input type="text" id="lastName" class="form-control login-inputs" placeholder="Enter Your Last Name"
                  v-model="lastName" required />
                <small v-if="lastNameError" class="text-danger">{{ lastNameError }}</small>
              </div>
              <div class="mb-3">
                <label class="font-color-g font-bold" for="email">Email Address</label>
                <input type="email" id="email" class="form-control login-inputs" placeholder="Enter Your Email Address"
                  v-model="email" required />
                <small v-if="emailError" class="text-danger">{{ emailError }}</small>
              </div>
              <div class="mb-3 position-relative">
                <label class="font-color-g font-bold" for="password">Create Password</label>
                <input :type="fieldType" id="password" class="form-control login-inputs"
                  placeholder="Enter Your Password" v-model="password" @input="checkPasswordRules"
                  @focus="showPasswordRules = true" required />
                <span @click="togglePasswordVisibility" class="password-toggle-icon">
                  <i :class="fieldType === 'password' ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"></i>
                </span>
                <small v-if="passwordError" class="text-danger">{{ passwordError }}</small>
                <ul v-show="showPasswordRules" class="password-rules mt-2 list-unstyled">
                  <li :class="{ 'text-success': passwordRules.length }">At least 8 characters long</li>
                  <li :class="{ 'text-success': passwordRules.uppercase }">Contains 1 uppercase character</li>
                  <li :class="{ 'text-success': passwordRules.numberOrSymbol }">Contains 1 number or symbol</li>
                </ul>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-primary btn-lg font-bold form-control mb-3">Sign Up</button>
                <div class="small text-muted mt-2">
                  By proceeding, you agree to <a href="https://sodamarketing.ai/terms-of-service/"
                    target="_blank">SodaMarketing's terms and conditions</a>
                </div>
              </div>
            </form>
            <router-link to="/login">
              <div class="text-center">
                <p class="p-color font-bold mt-4">Already Have An Account?</p>
              </div>
            </router-link>
          </div>
        </div>
        <!-- Right Section for Desktop -->

        <div class="col col-sm-5 login-right-content bubbles-register rows">

          <div class="row padding-pss">
            <div class="text-content text-white p-4 bg-text text-center">
              <h4>AI technology with the flavor of a human touch is like adding soda to your favorite drink</h4>
              <p>Create your content and designs for your target audience with minimal effort and the best
                creativity
                of
                artificial intelligence combined with the human touch.</p>
            </div>
            <div class="text-center">
              <img src="/assets/images/register-1600-1200-.png" class="sliderimage" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import { subscribe } from "@/apis";
import globalErrorHandler from "@/globalErrorHandler";

export default {
  name: "register",
  data() {
    return {
      registerError: false,
      messages: [],
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      isLoading: false,
      fieldType: "password",
      passwordRules: {
        length: false,
        uppercase: false,
        numberOrSymbol: false,
      },
      showPasswordRules: false,
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      passwordError: "",
    };
  },
  methods: {
    registerPost() {
      // Front-end validation
      this.validateFields();
      if (this.firstNameError || this.lastNameError || this.emailError || this.passwordError) {
        return;
      }

      this.isLoading = true;
      this.registerError = false;
      subscribe({
        email: this.email.trim(),
        password: this.password,
        first_name: this.firstName.trim(),
        last_name: this.lastName.trim(),
      })
        .then((res) => {
          // Track GTM event for Sign-up
          this.$gtm.trackEvent({
            event: "Sign-up",
            category: "User",
            action: "Register",
            label: this.email,
            value: 1, // Optional: Indicates successful signup
            firstName: this.firstName.trim(),
            lastName: this.lastName.trim(),
            email: this.email.trim(),
          });
          this.isLoading = false;
          this.$router.push({ path: "/emailverify/" + this.email });
        })
        .catch((err) => {
          this.messages = globalErrorHandler(err);
          this.registerError = true;
          this.isLoading = false;
          window.scroll(0, 0);
        });
    },
    togglePasswordVisibility() {
      this.fieldType = this.fieldType === "password" ? "text" : "password";
    },
    checkPasswordRules() {
      this.showPasswordRules = true;
      this.passwordRules.length = this.password.length >= 8;
      this.passwordRules.uppercase = /[A-Z]/.test(this.password);
      this.passwordRules.numberOrSymbol = /[0-9!@#$%^&*]/.test(this.password);
    },
    validateFields() {
      this.firstNameError = this.firstName.trim() ? "" : "First name is required.";
      this.lastNameError = this.lastName.trim() ? "" : "Last name is required.";
      this.emailError = this.validateEmail(this.email) ? "" : "Please enter a valid email address.";
      if (!this.password) {
        this.passwordError = "Password is required.";
      } else if (!this.passwordRules.length || !this.passwordRules.uppercase || !this.passwordRules.numberOrSymbol) {
        this.passwordError = "Password does not meet the requirements.";
      } else {
        this.passwordError = "";
      }
    },

    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
};
</script>

<style scoped>
.login-page-main {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-height: 100vh !important;
  justify-content: center;
  display: flex;
  align-items: center;
}

.login-background {
  background-color: #f9f9f9;
}

.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 50%;
  width: 100%;
}

@media (max-width: 768px) {
  .form-container {
    max-width: 100%;
  }
}

/* Tablet devices (between 768px and 991.98px) */
@media (max-width: 991.98px) and (min-width: 768px) {
  .form-container {
    max-width: 70%;
    padding: 1.5rem;
  }
}

/* Mobile devices (less than 768px) */
@media (max-width: 767.98px) {
  .form-container {
    max-width: 100%;
    /* Full width on mobile */
  }
}

/* Laptop screens (992px to 1279.98px) */
@media (min-width: 992px) and (max-width: 1279.98px) {
  .form-container {
    max-width: 70%;
    /* 70% width on laptops */
  }
}

/* Large screens (1280px and above) */
@media (min-width: 1280px) {
  .form-container {
    max-width: 50%;
    /* 50% width on large screens */
  }
}

.login-logo {
  width: 40%;
}

.login-form-section {
  padding: 2rem;
  background-color: white;
}

.password-rules {
  font-size: 0.9rem;
  line-height: 1.5;
  color: #6c757d;
}

.password-rules li.text-success {
  color: #28a745 !important;
}

.password-toggle-icon {
  position: absolute;
  top: 38px;
  right: 15px;
  cursor: pointer;
  color: #6c757d;
}



.rightsection {

  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-right: 0px !important;
  margin-right: 0px !important;

}

.card {
  background-color: #ffffff;
}

.login-right {
  padding: 2rem;
}

.text-content {
  font-family: Arimo;
  margin-bottom: 1.5rem;
  color: black !important;
}

.sliderimage {
  max-width: 100%;
}

.btn-primary {
  background-color: #f50975;
  font-size: 1.2rem;
  padding: 0.75rem;
}

.btn-primary:hover {
  background-color: #E26600;
  /* Brand color */
}

@media (max-width: 576px) {
  .login-right-content {
    display: none;
  }

  .rightsection {
    display: none;
  }
}

@media (max-width: 767px) {
  .login-logo {
    width: 50%;
  }

  .login-form-section {
    padding: 1.5rem;
  }
}

img.sliderimage {
  width: 100%;
}

.container-fluid {
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 100vh;
  justify-content: center;
  display: flex;
  background-color: #ffffff !important;
}

.login-form {

  border-radius: 0px;
  padding-top: 10px !important;
  padding-bottom: 5px !important;

}

:root {
  background-color: #ffffff !important;
}

.padding-pss {
  padding-right: 20px;
  padding-left: 20px;

}

.bg-text {
  background: #f0f0f0 !important;
}


.col.col-sm-7.d-flex.justify-content-center.align-items-center {
  background: #ffffff !important;
}
</style>
