<template>
  <div class="row">
    <div class="col-12">
      <div class="alert alert-danger text-center mx-4 p-1 br-radius" v-if="errors">
        <span v-for="(error,index) in errors" :key="index">
          {{ error }}
        </span>
      </div>
      <div class="row pt-3">
        <div class="col">
          <div class="text-color">Payments</div>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Type</th>
          <th scope="col">Amount</th>
          <th scope="col">Status</th>
          <th scope="col">Invoice</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(row,index) in rows" :key="index">
          <td>...</td>
          <td>...</td>
          <td class="ps-sm-3">... ...</td>
          <td class="ps-sm-3">...</td>
          <td class="ps-sm-3" v-if="row">
            <a>
              <i class="fa-regular fa-circle-down billing-color font-26"></i>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {listInvoices} from '@/apis';
import globalErrorHandler from "@/globalErrorHandler";

export default {
  name: 'Invoices',
  data() {
    return {
      rows: [],
      errors: null,
    }
  },
  mounted() {
    this.retrieveInvoices();
  },
  methods: {
    async retrieveInvoices() {
      try {
        const invoices = await listInvoices();
        this.rows = invoices.results;
      } catch (error) {
        
        this.errors = globalErrorHandler(error.data);
      }
    }
  },
}
</script>