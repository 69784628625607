<template>
  <div class="login-page-main">
    <div class="container-fluid bg-bubbles ps-0 login-background">
      <div class="row">
        <div class="col col-sm-5 login-form min-vh-100">
          <div class="row py-0">
            <div class="col text-center">
              <img src="/assets/images/logo-soda.svg" class="w-75 login-logo" alt="">
            </div>
          </div>
          <div class="row pt-1">
            <div class="col">
              <div v-if="message.length > 0" class="alert alert-danger br-radius mx-4 p-1 text-center">
                <span v-for="err in message">{{ err }}</span>
              </div>
              <form @submit.prevent="loginFN()">
                <div class="row py-3">
                  <div class="col">
                    <strong class="title-login">LOG IN</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="col pb-2">
                    <label class="font-color-g font-bold" for="">Email</label>
                    <div class="input-group">
                      <span class="input-group-text custom-icon-bg">
                        <i class="fa fa-envelope custom-icon-color"></i>
                      </span>
                      <input type="email" id="userName" class="form-control login-inputs"
                        placeholder="Enter Your Email Address" aria-label="Email" v-model="email">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col py-2 face-fram ">
                    <div class="d-flex justify-content-between align-items-center">
                      <label class="font-color-g font-bold mb-0" for="">Password</label>
                      <span v-if="fieldType == 'password'" @click="togglePasswordVisibility" class="login-icon">
                        <i class='fa-regular fa-eye-slash custom-icon-color'></i>
                      </span>
                      <span v-else @click="togglePasswordVisibility" class="login-icon">
                        <i class='fa-regular fa-eye custom-icon-color'></i>
                      </span>
                    </div>
                    <div class="input-group">
                      <span class="input-group-text custom-icon-bg">
                        <i class="fas fa-lock custom-icon-color"></i>
                      </span>
                      <input :type="fieldType" v-model="password" class="form-control login-inputs"
                        placeholder="Enter Your Password" aria-label="Password" />
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-2">
                  <!-- Remember Me Checkbox -->
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="rememberMe" />
                    <label class="form-check-label" for="rememberMe">
                      Remember me
                    </label>
                  </div>
                  <!-- Forgot Password Link -->
                  <router-link to="/forgetpassword">
                    <div>
                      <a href="#" class="font-color-g small">Forgot Password?</a>
                    </div>
                  </router-link>
                </div>
                <div class="row py-2">
                  <div v-if="isLoading" class="col text-center">
                    <loader />
                  </div>
                  <div v-else class="col">
                    <button type="submit" class="form-control bg-button-h font-bold">Login</button>
                  </div>
                </div>
                <router-link to="/register">
                  <div class="row py-2">
                    <div class="col text-center">
                      <div class="p-color font-bold">Create an account</div>
                    </div>
                  </div>
                </router-link>
              </form>
            </div>
            <!-- Social Media Icons -->
            <!-- <div class="row py-0 continue-with">
              <div class="col text-center">
                <div class="mx-1 mx-sm-2 text-muted text-decoration-none small">Or Continue With</div>
              </div>
            </div>
            <div class="row py-2">
              <div class="col-12">
                <div class="d-flex justify-content-center align-items-center">
                  <a href="#" class="mx-4">
                    <img src="/assets/images/google.svg" alt="Google" class="social-icon" />
                  </a>
                  <a href="#" class="mx-4">
                    <img src="/assets/images/facebook.svg" alt="Facebook" class="social-icon" />
                  </a>
                </div>
              </div>
            </div> -->
            <!-- Terms of Service, Privacy Policy, Security Links -->
            <!-- Mobile and Tablet Version -->
            <div class="row py-5 d-block d-lg-none">
              <div class="col-12">
                <div class="d-flex justify-content-center flex-nowrap text-muted footer-links overflow-hidden">
                  <a href="#" class="mx-1 mx-sm-2 text-muted text-decoration-none small">Terms of Service</a>
                  <span class="mx-1">|</span>
                  <a href="#" class="mx-1 mx-sm-2 text-muted text-decoration-none small">Privacy Policy</a>
                  <span class="mx-1">|</span>
                  <a href="#" class="mx-1 mx-sm-2 text-muted text-decoration-none small">Security</a>
                </div>
              </div>
            </div>
            <!-- Desktop Version -->
            <div class="py-4 fixed-bottom start-0 col-5 pe-5 d-none d-lg-block">
              <div class="col-12">
                <div class="d-flex justify-content-center flex-nowrap text-muted footer-links overflow-hidden">
                  <a href="#" class="mx-1 mx-sm-2 text-muted text-decoration-none small">Terms of Service</a>
                  <span class="mx-1">|</span>
                  <a href="#" class="mx-1 mx-sm-2 text-muted text-decoration-none small">Privacy Policy</a>
                  <span class="mx-1">|</span>
                  <a href="#" class="mx-1 mx-sm-2 text-muted text-decoration-none small">Security</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm-7 login-right-content">
          <div class="login-right">
            <div class="row padding-p">
              <div class="col text-center text-white bg-text">
                <h4>AI technology with the flavor of a human touch
                  is like adding soda to your favorite drink
                </h4>
                <h5 class="font-10">Create your content and designs for your target audience with minimal effort and the
                  best creativity of artificial intelligence combined with the human touch.</h5>
              </div>
            </div>
            <div class="row">
              <div class="col text-center">
                <img src="/assets/images/slider.png" class="sliderimage" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginSession } from '@/apis';
import globalErrorHandler from '@/globalErrorHandler';

export default {
  name: 'login',
  data() {
    return {
      loginError: false,
      message: [],
      email: '',
      password: '',
      isLoading: false,
      fieldType: 'password',
      changeClass: false,
    }
  },
  methods: {
    async loginFN() {
      this.isLoading = true;
      this.loginError = false;
      this.message = [];

      try {
        // Attempt login using the new `loginSession` API method
        const res = await loginSession({
          email: this.email.trim(),
          password: this.password
        });

        // On success, store the user data in Vuex
        this.$store.commit('SET_USER_DATA', { token: res.token, user: res.user });

        // Redirect to the home page
        window.location.href = '/';
      } catch (err) {
        // Check if the server returned error_num = 303 before handling other errors
        if (err.data?.error_num === 303) {
          this.isLoading = false;
          // Redirect to email verification
          return this.$router.push({ path: "/emailverify/" + this.email });
        }

        // If not 303, proceed with normal error handling
        this.message = globalErrorHandler(err);
        this.loginError = true;
        window.scroll(0, 0);
      } finally {
        // Always reset loading state
        this.isLoading = false;
      }
    },
    togglePasswordVisibility() {
      this.fieldType = this.fieldType === 'password' ? 'text' : 'password';
    }
  }
}
</script>
